
import Chart from "react-apexcharts";


const BarChart = ({ data }) => {


    const categories = data?.top_5_editions_by_payments.map(
      (edition) => edition.edition_name
    );
    const seriesData = data?.top_5_editions_by_payments.map(
      (edition) => edition.total_paid
    );
  
    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "bar",
        height: 180,
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          distributed: true,
          barHeight: 19,
          dataLabels: { position: "bottom" },
        },
      },
      stroke: { show: true, width: 3, colors: ["transparent"] },
      dataLabels: {
        enabled: false,
        textAnchor: "start",
        offsetX: 0,
        style: {
          fontSize: "10px",
          fontWeight: "600",
          align: "left",
        },
      },
      legend: { show: false },
      colors: ["#7843e6", "#b794ff", "#cdb5ff", "#a7a2f0", "#e7a2c0"],
      xaxis: {
        categories: categories,
        labels: {
          formatter: function (e) {
            return e + "";
          },
          style: {
            colors: "#252F4A",
            fontSize: "12px",
            fontWeight: "600",
            align: "left",
          },
        },
        axisBorder: { show: false },
      },
      yaxis: {
        labels: {
          // formatter: function (value) {
          //   return value + " - " + parseInt((100 * value) / 317450) + "%";
          // },
          formatter: function (value) {
            return value;
          },
          style: {
            colors: "#252F4A",
            fontSize: "12.5px",
            fontWeight: "700",
          },
          offsetY: 3,
          align: "right",
        },
      },
      grid: {
        borderColor: "#DBDFE9",
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: false } },
        strokeDashArray: 2,
      },
      tooltip: {
        style: { fontSize: "12px" },
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
    };
  
    const chartSeries = [
      {
        name: "Total Payment",
        data: seriesData,
      },
    ];
  
    return (
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={200}
      />
    );
  };


  export default BarChart