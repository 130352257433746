import React from "react";
import { LIMITS } from "../config";
import PaginationModule from "react-js-pagination";

export default function Pagination({
  data,
  limit,
  setLimit,
  activePage,
  totalRecords,
  onChange,
}) {
  return (
    <div>
      {data.length > 0 && (
        <div className="d-flex flex-stack flex-wrap pt-5 pe-7">
          <div className="fs-7 fw-bold text-gray-700 Showing d-flex align-items-center">
            <select
              className="form-select form-select-sm form-select-solid text-center fw-bolder w-75px me-3"
              onChange={(e) => {
                setLimit(e.target.value);
              }}
            >
              {LIMITS.map((item, i) => {
                return (
                  <option value={item} key={i}>
                    {item}
                  </option>
                );
              })}
            </select>
            Showing {data.length} of {totalRecords} entries
          </div>
          <PaginationModule
            activePage={activePage}
            itemsCountPerPage={limit}
            totalItemsCount={totalRecords}
            pageRangeDisplayed={5}
            itemClass="page-item"
            linkClass="page-link"
            activeLinkClass="active"
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
}
