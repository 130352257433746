import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";

import toast from "react-hot-toast";

import { DatePicker, message, Select, Tabs, TimePicker, Tooltip } from "antd";
import { fetchData } from "../../../../../config/service";
import { validateFields } from "../../../../../components/validation";
import moment from "moment";
import dayjs from "dayjs";

export default function ImportCsvModal({
  show,
  onHide,
  data,
  selectImportTab,
}) {
  const [sending, setSending] = useState(false);

  // State object for form fields
  const [formState, setFormState] = useState({
    day: "",
    date: "",
  });

  useEffect(() => {
    if (data?.id) {
      // console.log(data, "data");
      setFormState({
        day: "",
        date: "",
      });
    } else {
      setFormState({
        day: "",
        date: "",
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const clearInput = () => {
    setFormState({
      day: "",
      date: "",
    });
  };

  const rules = {
    day: {
      required: true,
      type: "number",
      min: 1,
      max: 366,
      message: "Please enter a valid Day number",
    },
    date: {
      required: true,
    },
  };

  const onSubmit = () => {
    // const { isValid, errors } = validateFields(formState, rules);

    // if (!isValid) {
    //   return;
    // } else {
    //   toast.loading("Adding day");
    // }

    if (!file) {
      alert("Please select a file first!");
      return;
    }

    setSending(true);

    const body = {
      related_edition: data?.edition_id,
      file: file,
    };

    let api =
      selectImportTab == 1
        ? `api/itinerary/upload-itinerary/`
        : "api/gameplan/upload-gameplan/";

    console.log(body, api, "body");

    // let method = data?.id ? "put" : "post";

    fetchData(api, "POST", body, "", true).then((res) => {
      if (res.success) {
        toast.dismiss();
        onHide();
        toast.success(res.message);
      } else {
        toast.dismiss();
        toast.error(res.message);
      }
    });
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Save the selected file to state
  };

  return (
    <Modal centered show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>
        <h3 className="modal-title">Import CSV</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-10">
              <a
                href={`${
                  selectImportTab == 1
                    ? "/media/bhx-gameplan.xlsx"
                    : "/media/bhx-gameplan.xlsx"
                }`}
                download
                className="fw-bold text-primary"
              >
                <i className="bi bi-download text-primary me-2" /> Download an
                import schema
              </a>
            </div>

            <div className="">
              <input
                type="file"
                className="form-control"
                accept=".xls,.xlsx,.csv"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => onSubmit()} // Handle form submission here
        >
          Upload File
        </button>
      </Modal.Footer>
    </Modal>
  );
}
