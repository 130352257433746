import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "antd";

export default function CustomerModal({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    status: null,
    score: null,
  });

  useEffect(() => {
    if (data) {
      console.log(data, "user_data");
      setUserId(data?.uuid);
      setCustomerData({
        status: data?.status,
        score: data?.score,
      });
    }
  }, [data]);

  console.log(customerData, "customerData");

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      ...(customerData.status !== data?.status
        ? { status: customerData.status }
        : {}),
      ...(customerData.score !== data?.score
        ? { score: customerData.score }
        : {}),
    };

    console.log(body, "body");

    fetchData(`customer/api/customer/${userId}/`, "PATCH", body).then((res) => {
      setSending(false);
      if (res.success) {
        console.log(res);
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setCustomerDataValue = (key, value) => {
    const common_data = {
      ...customerData,
      [key]: value,
    };

    setCustomerData(common_data);
  };

  const scoreOptions = [
    { value: 1, label: "1" },
    { value: 1.5, label: "1.5" },
    { value: 2, label: "2" },
    { value: 2.5, label: "2.5" },
    { value: 3, label: "3" },
    { value: 3.5, label: "3.5" },
    { value: 4, label: "4" },
    { value: 4.5, label: "4.5" },
    { value: 5, label: "5" },
  ];

  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Change Score <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-9 align-items-center">
          {/* <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">Select Status</span>
            </label>

            <Select
              value={customerData?.status}
              onChange={(e) => {
                setCustomerDataValue("status", e);
              }}
              placeholder="Select Status"
              className="fs-7 w-100"
              style={{ width: "100%" }}
              size="large"
              options={statusList?.map((e) => ({
                value: e?.value,
                label: e?.display_name,
              }))}
              dropdownStyle={{ zIndex: 1100 }}
            ></Select>
          </div> */}

          <div className="col-12 fv-row mt-5">
            <label>
              <span className="required fw-semibold fs-5">Select Score</span>
            </label>

            <Select
              value={customerData?.score}
              onChange={(e) => {
                setCustomerDataValue("score", e);
              }}
              placeholder="Select Score"
              className="fs-7 w-100"
              style={{ width: "100%" }}
              size="large"
              options={scoreOptions}
              dropdownStyle={{ zIndex: 1100 }}
            ></Select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
