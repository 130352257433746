// Itinerary.js
import React from "react";

const Itinerary = ({ cardData, onEdit }) => {
  return (
    <div className="card-body scroll">
      <div className="h-600px">
        {cardData.length > 0 ? (
          cardData.map((data) => (
            <div
              className="bg-light p-5 mb-5 rounded position-relative"
              key={data.id}
            >
              {/* Edit Button */}
              <div className="position-absolute top-0 end-0 p-3">
                <i
                  className="ki-outline ki-pencil fs-2 text-info cursor-pointer"
                  onClick={() => onEdit(data)}
                />
              </div>

              <div className="row h-100">
                <div className="col-md-5 mb-10 mb-sm-0">
                  <div
                    className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-300px min-h-sm-100 h-100"
                    style={{
                      backgroundSize: "100% 100%",
                      backgroundImage: `url(${
                        data.default_image
                          ? data.default_image
                          : "https://placehold.co/600x400/png"
                      })`,
                    }}
                  ></div>
                </div>

                <div className="col-md-7">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-0">
                      <div className="d-flex flex-stack mb-3">
                        <div className="flex-shrink-0 me-5">
                          <span className="text-gray-700 fs-7 fw-bold me-2 d-block lh-1 pb-1">
                            <span className="text-gray-900">
                              {data.day_num}
                            </span>{" "}
                            Day
                          </span>
                          <span className="text-gray-800 fs-3 fw-bold text-one w-275px">
                            {data.title}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="mb-0">
                      <div className="d-flex align-items-center mb-2">
                        <div className="symbol symbol-30px symbol-circle me-3">
                          <span className="symbol-label bg-primary">
                            <i className="ki-outline ki-delivery-door fs-5 text-white" />
                          </span>
                        </div>
                        <div className="m-0">
                          <span className="fw-semibold text-gray-800 fs-7">
                            <span className="fw-bolder">Hotel:</span>{" "}
                            {data.hotel_name}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center mb-2">
                        <div className="symbol symbol-30px symbol-circle me-3">
                          <span className="symbol-label bg-primary">
                            <i className="ki-outline ki-geolocation-home fs-5 text-white" />
                          </span>
                        </div>
                        <div className="m-0">
                          <span className="fw-semibold text-gray-800 fs-7">
                            <span className="fw-bolder">Destination:</span>{" "}
                            {data.destination_name}
                          </span>
                        </div>
                      </div>

                      <div className="mb-3">
                        {/* Display Multiple Agendas */}
                        {Array.isArray(data.agenda) &&
                        data.agenda.length > 0 ? (
                          <div className="mb-2">
                            <span className="fw-semibold text-gray-800 fs-7">
                              <span className="fw-bolder">Agenda:</span>
                            </span>
                            <ul className="">
                              {data.agenda.map((agenda, index) => (
                                <li key={index} className=" py-1">
                                  <span className="fw-semibold text-gray-800 fs-7">
                                    {agenda}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <span className="fw-semibold text-gray-600 fs-6 d-block fs-7">
                            {data.agenda}
                          </span>
                        )}
                      </div>

                      <div className="separator separator-dashed border-gray-300 my-3" />
                      <div className="mb-3">
                        <span className="fw-semibold text-gray-800 fs-7">
                          <span className="fw-bolder">
                            Experience to Itinerary:
                          </span>{" "}
                          {data.experience}
                        </span>
                      </div>
                      <div className="m-0">
                        <span className="fw-semibold text-gray-800 fs-7">
                          <span className="fw-bolder">About this day:</span>{" "}
                          {data.about_this_day}
                        </span>
                      </div>
                    </div>
                    {data.trip_highlight && (
                      <div className="badge badge-light-primary mt-3">
                        Trip Highlight
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="row g-5 mb-0 gx-md-10">
            <div className="col-md-12 text-center">
              <div className="h-600px d-flex flex-column align-items-center justify-content-center">
                <div className="mt-0">
                  <img
                    src="/media/no-data-found.png"
                    className="mw-25"
                    alt="No Data Found"
                  />
                </div>
                <h1 className="mt-4">No Data Found</h1>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Itinerary;
