import React, { useEffect, useState } from "react";
import { LIMITS, routesList } from "../../../../config";
import { Link, useParams } from "react-router-dom";
import { Upload, Button, message, Select, DatePicker, Tooltip } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { validateFields } from "../../../../components/validation";
import dayjs from "dayjs";
import { CustomerModal, StatusModal } from "../../../customers/modals";

export default function BookingDetail() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [isOpenScoreModal, setIsOpenScoreModal] = useState(false);

  const [statusList, setStatusList] = useState([]);

  const [formData, setFormData] = useState({
    uuid: "",
    user: "",
    email: "",
    score: "",
    status: "",
    status_value: null,
    fieldOfWork: null,
    category: null,
    annualRevenue: null,
    identity: null,
    dateOfBirth: "",
    reasonsToAttend: "",
    areasOfGrowth: "",
    designation: "",
    story: "",
    instagramProfile: "",
    linkedinProfile: "",
    companyWebsite: "",
    hasReferral: false,
    referralName: "",
    intentions: "",
    edition_name: "",
  });

  useEffect(() => {
    if (id) {
      getBookingDetailData();
      getStatus();
    }
  }, [id]);

  const getStatus = () => {
    fetchData(
      `api/accounts/user-choices/?type=user_status_choices`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setStatusList(res["user_status_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  //   useEffect(() => {
  //     getFieldOfWork();
  //     getCategory();
  //     getAnnualRevenue();
  //   }, []);

  const clearInputs = () => {
    setFormData({
      uuid: "",
      user: "",
      email: "",
      score: "",
      status: "",
      status_value: null,
      fieldOfWork: null,
      category: null,
      annualRevenue: null,
      identity: null,
      dateOfBirth: "",
      reasonsToAttend: "",
      areasOfGrowth: "",
      designation: "",
      story: "",
      instagramProfile: "",
      linkedinProfile: "",
      companyWebsite: "",
      hasReferral: false,
      referralName: "",
      intentions: "",
      edition_name: "",
    });
  };

  const getBookingDetailData = () => {
    setLoading(true);
    fetchData(`api/booking/${id}`).then((res) => {
      setLoading(false);
      if (res.success) {
        const data = res;
        const user = data?.user;

        const mappedData = {
          uuid: user.uuid || "",
          user: `${user.first_name} ${user.last_name}`,
          email: user.email || "",
          score: user.score || "",
          status: data.status_labels || "",
          status_value: data.status,
          fieldOfWork: user.user_work[0]?.field_work_label || null,
          category: user.user_work[0]?.category_label || null,
          annualRevenue: user.user_work[0]?.revenue_label || null,
          identity: user.user_profile?.identify_label || null,
          dateOfBirth: user.date_of_birth || "",
          reasonsToAttend:
            data.top_priority_labels.length > 0
              ? data.top_priority_labels.join(", ")
              : "",
          areasOfGrowth:
            data.growth_areas_labels.length > 0
              ? data.growth_areas_labels.join(", ")
              : "",
          designation: user.user_work[0]?.designation || "",
          story: user?.user_profile ? user.user_profile?.bio : "-",
          instagramProfile: user?.user_profile
            ? user.user_profile?.instagram_url
            : "-",
          linkedinProfile: user?.user_profile
            ? user.user_profile?.linkedin_url
            : "-",
          companyWebsite: user?.user_profile
            ? user.user_work[0]?.work_url
            : "-",
          hasReferral: false,
          referralName: "",
          intentions: data?.intentions,
          edition_name: data?.edition_name,
        };

        console.log(mappedData, "mappedData");

        setFormData(mappedData);
      } else {
        setLoading(false);
        clearInputs();
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div
        className="row g-5 g-xxl-10"
        style={
          loading
            ? {
                filter: "blur(5px)",
                pointerEvents: "none",
              }
            : {}
        }
      >
        <div className={`${id ? "col-xxl-12" : "col-xxl-12"}`}>
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header ">
              <h3 className="card-title fw-bold mb-0 fs-3 d-flex align-items-center">
                <Link
                  to={routesList["bookings"].path}
                  className="d-flex me-3 btn btn-sm btn-icon btn-light-primary"
                >
                  <i className="ki-outline ki-arrow-left fs-2 " />
                </Link>
                Booking Details
              </h3>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body  pe-0">
              {/* <div className="scroll-y h-700px pe-5"> */}
              <div className="h-720px pe-5">
                <div className="row g-5 mb-5 align-items-center">
                  {/* New row for user, email, score, and status */}
                  <div className="row g-5 align-items-center">
                    <div className="row align-items-center">
                      <label className="fw-bold fs-2 mb-2">
                        Personal Details
                      </label>
                    </div>

                    <div className="col-md-2 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        User
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.user || "-"}
                      </p>
                    </div>

                    <div className="col-md-3 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Email
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.email || "-"}
                      </p>
                    </div>

                    <div className="col-md-2 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Edition
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.edition_name || "-"}
                      </p>
                    </div>

                    <div
                      className="col-md-1 fv-row mb-10"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsOpenScoreModal(!isOpenScoreModal);
                      }}
                    >
                      <Tooltip title="Click To Change Score">
                        <label className="fs-6 text-gray-600 fw-semibold mb-2">
                          Score
                        </label>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fs-5 text-gray-800 fw-bold d-flex align-items-center">
                            <span className="badge badge-primary ms-2">
                              {formData?.score || "-"}
                            </span>

                            <i className="ki-outline ki-down fs-2 text-primary ms-2"></i>
                          </div>
                        </div>
                      </Tooltip>
                    </div>

                    <div
                      className="col-md-2 fv-row mb-10"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsOpenUserModal(!isOpenUserModal);
                      }}
                    >
                      <Tooltip title="Click To Change Status">
                        <label className="fs-6 text-gray-600 fw-semibold mb-2">
                          Status
                        </label>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fs-5 text-gray-800 fw-bold d-flex align-items-center">
                            <span
                              className={`badge badge-light-${
                                formData?.status_value == 1
                                  ? "warning"
                                  : formData?.status_value == 2
                                  ? "danger"
                                  : formData?.status_value == 3
                                  ? "success"
                                  : "dark"
                              }`}
                            >
                              {formData?.status || "-"}
                            </span>

                            <i className="ki-outline ki-down fs-2 text-primary ms-1"></i>
                          </div>
                        </div>
                      </Tooltip>
                    </div>

                    <div className="col-md-2 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Date of Birth
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {dayjs(formData?.dateOfBirth).format("DD-MM-YYYY") ||
                          "-"}
                      </p>
                    </div>
                  </div>

                  <div className="row g-5 align-items-center">
                    <div className="row align-items-center">
                      <label className="fw-bold fs-2 mb-2">Work Details</label>
                    </div>

                    <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Pick your field of work
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.fieldOfWork || "-"}
                      </p>
                    </div>

                    <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Pick a relevant category
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.category || "-"}
                      </p>
                    </div>

                    {/* <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Pick your annual revenue
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.annualRevenue || "-"}
                      </p>
                    </div> */}

                    <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Pick your annual revenue
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.annualRevenue || "-"}
                      </p>
                    </div>

                    <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        You identify as
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.identity || "-"}
                      </p>
                    </div>
                    <div className="col-md-4 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Designation
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.designation || "-"}
                      </p>
                    </div>

                    <div className="col-md-6 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Top 2 reasons to attend this experience
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.reasonsToAttend || "-"}
                      </p>
                    </div>

                    <div className="col-md-6 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Top 2 areas of growth
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.areasOfGrowth || "-"}
                      </p>
                    </div>

                    <div className="col-md-6 fv-row ">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Tell us your story to attend this adventure
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.story || "-"}
                      </p>
                    </div>

                    <div className="col-md-6 fv-row ">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Tell us your intention to attend this adventure
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.intentions || "-"}
                      </p>
                    </div>
                  </div>

                  <div className="row g-5 align-items-center">
                    <div className="row g-5 align-items-center">
                      <label className="fw-bold fs-2 mb-2">Socials</label>
                    </div>

                    {/* <div className="col-md-4 fv-row mb-5">
                    <label className="fs-6 text-gray-600 fw-semibold mb-2">
                      Instagram profile
                    </label>
                    <p className="fs-5 text-gray-800 fw-bold">
                      {formData?.instagramProfile}
                    </p>
                  </div>

                  <div className="col-md-4 fv-row mb-5">
                    <label className="fs-6 text-gray-600 fw-semibold mb-2">
                      LinkedIn profile
                    </label>
                    <p className="fs-5 text-gray-800 fw-bold">
                      {formData?.linkedinProfile}
                    </p>
                  </div> */}

                    <div className="col-md-12 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Company website / portfolio
                      </label>

                      <div>
                        {formData?.companyWebsite ? (
                          <a
                            href={formData?.companyWebsite}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="fs-5 text-primary fw-bold text-decoration-none"
                          >
                            {formData?.companyWebsite}
                          </a>
                        ) : (
                          <p className="fs-5 text-gray-800 fw-bold">-</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="row g-5 mb-5 align-items-center">
                  <div className="col-md-12 fv-row mb-5">
                    <label className="fs-6 text-gray-600 fw-semibold mb-2">
                      I have a referral from a tribe member
                    </label>
                    <p className="fs-6">
                      {formData?.hasReferral ? "Yes" : "No"}
                    </p>
                  </div>
                </div>

                {formData?.hasReferral && (
                  <div className="row g-5 mb-5 align-items-center">
                    <div className="col-md-12 fv-row mb-5">
                      <label className="fs-6 text-gray-600 fw-semibold mb-2">
                        Enter their full name for faster access
                      </label>
                      <p className="fs-5 text-gray-800 fw-bold">
                        {formData?.referralName}
                      </p>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>

      {isOpenUserModal && (
        <StatusModal
          show={isOpenUserModal}
          statusList={statusList}
          onHide={() => {
            setIsOpenUserModal(false);
            setLoading(true);
            getBookingDetailData();
          }}
          data={{
            uuid: id,
            status: formData?.status_value,
          }}
        />
      )}

      {isOpenScoreModal && (
        <CustomerModal
          show={isOpenScoreModal}
          statusList={statusList}
          onHide={() => {
            setIsOpenScoreModal(false);
            setLoading(true);
            getBookingDetailData();
          }}
          data={{
            uuid: formData?.uuid,
            score: formData?.score,
            status: formData?.status_value,
          }}
        />
      )}
    </div>
  );
}
