// import { configureStore } from "@reduxjs/toolkit";
// import reducer from "./slice";
// export const store = configureStore({
//   reducer: reducer,
// });

import { configureStore } from "@reduxjs/toolkit";
import reducer from "./slice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

// const SECRET_KEY = "your-secret-key"; // Use a strong secret key for encryption

// Function to encode state
const encrypt = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

// Function to decode state
const decrypt = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Create the transform
export const createTransform = () => ({
  in: (inboundState, key) => {
    // Encrypt the state before persisting
    return encrypt(inboundState);
  },
  out: (outboundState, key) => {
    // Decrypt the state after rehydrating
    return decrypt(outboundState);
  },
});

// Persist configuration
const persistConfig = {
  key: "root",
  storage, // Uses localStorage
  transforms: [createTransform()],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
