import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";

import { Select } from "antd";
import { fetchData } from "../../../../config/service";

export default function UserDetailModal({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    status: null,
    score: null,
  });

  useEffect(() => {
    if (data) {
      console.log(data, "user_data");
      setUserId(data?.uuid);
      setCustomerData({
        status: data?.status,
        score: data?.score,
      });
    }
  }, [data]);

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      ...(customerData.status !== data?.status
        ? { status: customerData.status }
        : {}),
      ...(customerData.score !== data?.score
        ? { score: customerData.score }
        : {}),
    };

    console.log(body, "body");

    fetchData(`customer/api/customer/${userId}/`, "PATCH", body).then((res) => {
      setSending(false);
      if (res.success) {
        console.log(res);
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setCustomerDataValue = (key, value) => {
    const common_data = {
      ...customerData,
      [key]: value,
    };
    console.log(common_data, "common_data");
    setCustomerData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="modal-title">
            {data?.user?.first_name + " " + data?.user?.last_name}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-5 mb-5 align-items-center">
          <div className="col-md-12 fv-row">
            <h6 className="text-muted">Founder PY INDSOL</h6>
            <p className="text-muted">scorer</p>

            <h5>Intro</h5>
            <h6>What can I offer?</h6>

            <h6>Bio in their form</h6>
            <p>
              At PY INDSOL, I focus on building a robust recommerce platform
              that connects buyers with reliable used machinery. My role
              involves fostering relationships with customers and suppliers to
              ensure quality and transparency. With nearly a decade of
              experience in the industry, I prioritize team collaboration and
              integrity. My commitment to mutual respect and trust drives our
              success and fosters a supportive business environment.
            </p>

            <p>
              I would like to attend BHX's retreat to connect with like-minded
              individuals who share a passion for innovation and
              entrepreneurship. I believe that engaging with diverse
              perspectives can spark new ideas and inspire creative solutions to
              challenges in the industry.
            </p>

            <p>
              Additionally, the retreat would provide an invaluable opportunity
              to learn from others' experiences and share insights from my own
              journey. I'm eager to foster lasting relationships with fellow
              creators, as I know that collaboration often leads to
              transformative opportunities and growth. Ultimately, I see this
              retreat as a chance to strengthen my network while contributing to
              a vibrant community focused on mutual support and shared success.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>

        <a
          href={`${data?.user?.user_profile?.instagram_url}`}
          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
          onClick={onHide}
        >
          <i className="lab la-instagram fs-2x text-primary" />
        </a>
        <a
          href={`${data?.user?.user_profile?.linkedin_url}`}
          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
          onClick={onHide}
        >
          <i className="lab la-linkedin fs-2x text-primary" />
        </a>
      </Modal.Footer>
    </Modal>
  );
}
