import React from "react";
import { Select, DatePicker, Typography } from "antd";
import { Loader, Pagination } from "../../../components";
import ReactApexChart from "react-apexcharts";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const LineCharts = () => {
  const chartOneOptions = {
    chart: {
      id: "line-chart-1",
    },
    grid: {
      show: false, // Hide the grid lines on both x and y axes
    },
    colors: ["#FF5733"], // Color for the first chart's line
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
  };

  const chartOneSeries = [
    {
      name: "Series 1",
      data: [10, 41, 35, 51, 49, 62],
    },
  ];

  const chartTwoOptions = {
    chart: {
      id: "line-chart-2",
    },
    grid: {
      show: false, // Hide the grid lines on both x and y axes
    },
    colors: ["#4287f5"], // Color for the second chart's line
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
  };

  const chartTwoSeries = [
    {
      name: "Series 2",
      data: [23, 42, 30, 47, 35, 60],
    },
  ];

  return (
    <div>
      <h2>Line Chart 1</h2>
      <ReactApexChart
        options={chartOneOptions}
        series={chartOneSeries}
        type="line"
        height={300}
      />

      <h2>Line Chart 2</h2>
      <ReactApexChart
        options={chartTwoOptions}
        series={chartTwoSeries}
        type="line"
        height={300}
      />
    </div>
  );
};

const AdMetrics = () => {
  // Mock data
  const mockData = [
    {
      title: "BHX",
      lead: 245,
      applied: 180,
      approved: 120,
      ticketsSold: 95,
      stats: {
        leadToApp: "73.4%",
        curatedToApproved: "66.7%",
        approvedToSale: "79.2%",
        convRatio: "38.8%",
      },
      funnel: {
        approved: { count: 120, total: 180, ratio: "66.7%" },
        rejected: { count: 60, total: 180, ratio: "33.3%" },
      },
    },
    {
      title: "Bucketlist",
      lead: 312,
      applied: 228,
      approved: 158,
      ticketsSold: 134,
      stats: {
        leadToApp: "73.1%",
        curatedToApproved: "69.3%",
        approvedToSale: "84.8%",
        convRatio: "42.9%",
      },
      funnel: {
        approved: { count: 158, total: 228, ratio: "69.3%" },
        rejected: { count: 70, total: 228, ratio: "30.7%" },
      },
    },
    {
      title: "XYZ",
      lead: 312,
      applied: 228,
      approved: 158,
      ticketsSold: 134,
      stats: {
        leadToApp: "73.1%",
        curatedToApproved: "69.3%",
        approvedToSale: "84.8%",
        convRatio: "42.9%",
      },
      funnel: {
        approved: { count: 158, total: 228, ratio: "69.3%" },
        rejected: { count: 70, total: 228, ratio: "30.7%" },
      },
    },
  ];

  const tableData = [
    {
      name: "BHX Prague & Berlin 4",
      date: "Apr 19, 2024",
      image: "path/to/image1.jpg", // Placeholder for image URL
      apps: 638,
      sold: 13,
      conv: "2.0%",
      fq: "47.6%",
      status: "Open",
      activeInvites: 0,
      deadInvites: 301,
      uncurated: 5,
      extensions: 0,
      daysLeft: -200,
    },
    {
      name: "BHX Shillong 14",
      date: "May 9, 2024",
      image: "path/to/image2.jpg", // Placeholder for image URL
      apps: 350,
      sold: 18,
      conv: "5.1%",
      fq: "55.4%",
      status: "Open",
      activeInvites: 0,
      deadInvites: 194,
      uncurated: 2,
      extensions: 0,
      daysLeft: -180,
    },
    {
      name: "BHX Beaches 21",
      date: "Jun 5, 2024",
      image: "path/to/image3.jpg", // Placeholder for image URL
      apps: 614,
      sold: 16,
      conv: "2.6%",
      fq: "48.5%",
      status: "Open",
      activeInvites: 0,
      deadInvites: 296,
      uncurated: 7,
      extensions: 0,
      daysLeft: -153,
    },
    {
      name: "BHX Sri Lanka 5",
      date: "Jun 24, 2024",
      image: "path/to/image4.jpg", // Placeholder for image URL
      apps: 134,
      sold: 8,
      conv: "6.0%",
      fq: "59.7%",
      status: "Open",
      activeInvites: 0,
      deadInvites: 80,
      uncurated: 2,
      extensions: 0,
      daysLeft: -134,
    },
    // Add more rows as necessary
  ];

  return (
    <>
      <div
        style={{
          padding: "16px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
        }}
      >
        {/* Title */}
        <Title level={4}>Team - Ad Metrics (ADM)</Title>

        <div className="row align-items-center">
          {/* Time Period Dropdown */}
          <div className="col-md-2">
            <Select
              placeholder="Last Quarter"
              style={{ width: "100%" }}
              allowClear
              size="large"
            >
              <Option value="lastQuarter">Last Quarter</Option>
              <Option value="lastMonth">Last Month</Option>
              <Option value="lastWeek">Last Week</Option>
              <Option value="thisQuarter">This Quarter</Option>
              <Option value="thisMonth">This Month</Option>
              <Option value="thisWeek">This Week</Option>
            </Select>
          </div>

          {/* Region/Type Dropdown */}
          <div className="col-md-2">
            <Select defaultValue="BHX" style={{ width: "100%" }} size="large">
              <Option value="BHX">BHX</Option>
              <Option value="ABC">ABC</Option>
              <Option value="XYZ">XYZ</Option>
            </Select>
          </div>

          {/* Date Range Picker */}
          <div className="col-md-4">
            <RangePicker style={{ width: "100%" }} size="large" />
          </div>

          {/* Interval Selector */}
          <div className="col-md-2">
            <Select defaultValue="7" style={{ width: "100%" }} size="large">
              <Option value="1">1</Option>
              <Option value="7">7</Option>
              <Option value="30">30</Option>
            </Select>
          </div>
        </div>
      </div>

      {/* <Design1 mockData={mockData} />

      <Design2 mockData={mockData} />

      <Design3 mockData={mockData} /> */}

      <Design4 mockData={mockData} />

      {/* <Design5 mockData={mockData} /> */}

      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12 ">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mr-4">
                  <h2>Open Editions</h2>
                </div>
              </div>
              <div className="card-toolbar">
                {" "}
                <Select
                  placeholder="Edition Status"
                  style={{ width: "150px" }}
                  allowClear
                  size="large"
                >
                  <Option value="open">Open</Option>
                  <Option value="close">Close</Option>
                  <Option value="pending">Pending</Option>
                </Select>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-5 pb-7">
              {/*begin::Table container*/}
              {false ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {tableData?.length > 0 ? (
                    <>
                      {/*begin::Table container*/}
                      <div className="table-responsive tbl-sales">
                        <table className="table align-middle gs-3 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-gray-900 bg-light-primary w-100">
                              <th className="min-w-200px">Name of Edition</th>
                              <th className="min-w-100px">Apps</th>
                              <th className="min-w-100px">Sold</th>
                              <th className="min-w-100px">Conv%</th>
                              <th className="min-w-100px">FQ</th>
                              <th className="min-w-100px">Status</th>
                              <th className="min-w-150px">Active Invites</th>
                              <th className="min-w-100px">Dead Invites</th>
                              <th className="min-w-100px">Uncurated</th>
                              <th className="min-w-100px">Extensions</th>
                              <th className="min-w-100px"> Days Left</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold fs-7">
                            {tableData.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3 cursor-pointer">
                                      {row?.image ? (
                                        <img
                                          src={row?.image}
                                          alt=""
                                          width={60}
                                        />
                                      ) : (
                                        <div class="symbol symbol-circle symbol-50px mr-3">
                                          <span
                                            class="symbol-label fs-2 text-primary bg-light-primary"
                                            style={{
                                              border: "1px dashed #7843e6",
                                              borderRadius: "50%",
                                            }}
                                          >
                                            {row?.name?.charAt(0).toUpperCase()}
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div className="d-flex flex-column">
                                      <div className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6">
                                        {row.name || "-"}
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td>{row.apps}</td>
                                <td>{row.sold}</td>
                                <td>{row.conv}</td>
                                <td>{row.fq}</td>
                                <td>
                                  <span
                                    className={`badge ${
                                      row.status === "Open"
                                        ? "badge-warning"
                                        : "badge-danger"
                                    }`}
                                  >
                                    {row.status}
                                  </span>
                                </td>
                                <td>{row.activeInvites}</td>
                                <td>{row.deadInvites}</td>
                                <td>{row.uncurated}</td>
                                <td>{row.extensions}</td>
                                <td>{row.daysLeft}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      {/* <Pagination
                        setLimit={setLimit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      /> */}
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>

      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12 ">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mr-4">
                  <h2>Charts</h2>
                </div>
              </div>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-5 pb-7">
              <LineCharts />
            </div>
            {/*begin::Body*/}
          </div>
        </div>
      </div>
    </>
  );
};

const Design1 = ({ mockData }) => {
  return (
    <div className="container mt-4">
      <div className="row">
        {mockData.map((item, index) => (
          <div className="col-md-6 mb-4" key={index}>
            <div className="card shadow-sm h-100">
              <div className="card-header bg-primary text-white d-flex align-items-center">
                <i className="ki-outline ki-briefcase me-2"></i>
                <h5 className="m-0">{item.title}</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <p>
                      <strong>Leads:</strong> {item.lead}
                    </p>
                    <p>
                      <strong>Applied:</strong> {item.applied}
                    </p>
                    <p>
                      <strong>Approved:</strong> {item.approved}
                    </p>
                    <p>
                      <strong>Tickets Sold:</strong> {item.ticketsSold}
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      <strong>Lead to App:</strong> {item.stats.leadToApp}
                    </p>
                    <p>
                      <strong>Curated to Approved:</strong>{" "}
                      {item.stats.curatedToApproved}
                    </p>
                    <p>
                      <strong>Approved to Sale:</strong>{" "}
                      {item.stats.approvedToSale}
                    </p>
                    <p>
                      <strong>Conversion Ratio:</strong> {item.stats.convRatio}
                    </p>
                  </div>
                </div>

                <div className="mt-3">
                  <h6 className="fw-bold">Funnel Stats</h6>
                  <div className="d-flex justify-content-between">
                    <div className="text-success">
                      <i className="ki-outline ki-check-circle me-1"></i>
                      Approved: {item.funnel.approved.count} /{" "}
                      {item.funnel.approved.total}({item.funnel.approved.ratio})
                    </div>
                    <div className="text-danger">
                      <i className="ki-outline ki-x-circle me-1"></i>
                      Rejected: {item.funnel.rejected.count} /{" "}
                      {item.funnel.rejected.total}({item.funnel.rejected.ratio})
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-light text-muted d-flex justify-content-between">
                <span>
                  <i className="ki-outline ki-lead me-1"></i> Leads Processed
                </span>
                <span>
                  <i className="ki-outline ki-tick me-1"></i> Sales Completed
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Design2 = ({ mockData }) => {
  return (
    <div className="container-fluid px-4 py-4">
      <div className="row flex-nowrap overflow-auto g-4">
        {mockData.map((data, index) => (
          <div className="col-12 col-xl-6" key={index}>
            <div className="card card-custom">
              <div className="card-header border-0">
                <h3 className="card-title fw-bold text-dark">{data.title}</h3>
                <div className="card-toolbar">
                  <button className="btn btn-sm btn-light me-2">
                    <i className="ki-outline ki-download fs-2"></i>
                    Download
                  </button>
                </div>
              </div>

              <div className="card-body py-4">
                {/* Metrics Row */}
                <div className="row g-4 mb-4">
                  {Object.entries(data)
                    .filter(([key]) =>
                      ["lead", "applied", "approved", "ticketsSold"].includes(
                        key
                      )
                    )
                    .map(([key, value]) => (
                      <div className="col-6 col-md-3" key={key}>
                        <MetricBox
                          icon={getIconForMetric(key)}
                          label={formatLabel(key)}
                          value={value}
                          color={getColorForMetric(key)}
                        />
                      </div>
                    ))}
                </div>

                {/* Ratios Row */}
                <div className="row g-4 mb-4">
                  {Object.entries(data.stats).map(([key, value]) => (
                    <div className="col-6 col-md-3" key={key}>
                      <RatioBox
                        label={formatLabel(key)}
                        value={value}
                        trend={getTrendForRatio(key, value)}
                      />
                    </div>
                  ))}
                </div>

                {/* Funnel Table */}
                <FunnelTable data={data.funnel} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Design3 = ({ mockData }) => {
  return (
    <div className="container-fluid p-6 bg-light">
      {/* Header with improved styling */}
      <div className="mb-8">
        <h2 className="text-dark fw-bolder fs-1">Performance Dashboard</h2>
        <div className="d-flex align-items-center">
          <i className="ki-outline ki-chart-line fs-2 text-primary me-2"></i>
          <p className="text-gray-600 fs-5 mb-0">
            Sales funnel and conversion metrics overview
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="row g-5">
        {mockData.map((item, index) => (
          <div key={index} className="col-12">
            <div className="card shadow-sm border-0 h-100">
              {/* Card Header with Stats Summary */}
              <div className="card-header border-0 bg-white py-5">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div className="d-flex align-items-center">
                    <i className="ki-outline ki-building fs-1 text-primary me-4"></i>
                    <div>
                      <h3 className="card-title fw-bolder text-dark fs-2 mb-2">
                        {item.title}
                      </h3>
                      <span className="text-gray-400">
                        Overall Conversion Rate:{" "}
                      </span>
                      <span className="text-primary fw-bold fs-4">
                        {item.stats.convRatio}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Main Metrics Cards */}
              <div className="card-body pt-0">
                <div className="row g-4">
                  {/* Leads Card */}
                  <div className="col-md-3">
                    <div className="card border-0 bg-light-primary h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-abstract-26 fs-1 text-primary"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Total Leads
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.lead}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="bullet bullet-vertical h-40px bg-primary me-3"></span>
                            <div className="fs-6">
                              <span className="text-gray-500">First Stage</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Applications Card */}
                  <div className="col-md-3">
                    <div className="card border-0 bg-light-info h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-document fs-1 text-info"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Applications
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.applied}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="bullet bullet-vertical h-40px bg-info me-3"></span>
                              <div className="fs-6">
                                <span className="text-gray-500">
                                  Lead to App
                                </span>
                              </div>
                            </div>
                            <span className="badge badge-light-info fs-6">
                              {item.stats.leadToApp}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Approved Card */}
                  <div className="col-md-3">
                    <div className="card border-0 bg-light-success h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-check fs-1 text-success"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Approved
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.approved}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="bullet bullet-vertical h-40px bg-success me-3"></span>
                              <div className="fs-6">
                                <span className="text-gray-500">
                                  Approval Rate
                                </span>
                              </div>
                            </div>
                            <span className="badge badge-light-success fs-6">
                              {item.stats.curatedToApproved}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Sales Card */}
                  <div className="col-md-3">
                    <div className="card border-0 bg-light-warning h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-dollar fs-1 text-warning"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Tickets Sold
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.ticketsSold}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="bullet bullet-vertical h-40px bg-warning me-3"></span>
                              <div className="fs-6">
                                <span className="text-gray-500">
                                  Close Rate
                                </span>
                              </div>
                            </div>
                            <span className="badge badge-light-warning fs-6">
                              {item.stats.approvedToSale}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Funnel Stats Redesigned */}
                <div className="row mt-8">
                  <div className="col-12">
                    <div className="card border-0 bg-white shadow-sm">
                      <div className="card-body p-5">
                        <div className="d-flex justify-content-between align-items-center mb-5">
                          <div className="d-flex align-items-center">
                            <i className="ki-outline ki-funnel fs-2 me-2 text-primary"></i>
                            <h4 className="fw-bold text-dark mb-0">
                              Funnel Analysis
                            </h4>
                          </div>
                          <div className="bg-light-primary rounded px-4 py-2">
                            <div className="d-flex align-items-center">
                              <i className="ki-outline ki-document-2 fs-2 text-primary me-2"></i>
                              <div>
                                <span className="text-gray-600 fs-6">
                                  Total Curated
                                </span>
                                <div className="fs-4 fw-bolder text-gray-900">
                                  {item.funnel.approved.total}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row g-5">
                          <div className="col-md-6">
                            <div className="card border-0 bg-light-success rounded h-100">
                              <div className="card-body p-4">
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-40px me-3">
                                      <div className="symbol-label bg-success">
                                        <i className="ki-outline ki-check text-inverse-success"></i>
                                      </div>
                                    </div>
                                    <div>
                                      <span className="fs-6 text-gray-600">
                                        Approved Applications
                                      </span>
                                      <div className="fs-4 fw-bold text-dark">
                                        {item.funnel.approved.count}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-end">
                                    <span className="badge badge-success fs-5 mb-2">
                                      {item.funnel.approved.ratio}
                                    </span>
                                    <div className="text-gray-600 fs-7">
                                      of total curated
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center mt-4">
                                  <span className="bullet bullet-vertical h-35px bg-success me-3"></span>
                                  <div className="text-gray-600 fs-7">
                                    {item.funnel.approved.count} out of{" "}
                                    {item.funnel.approved.total} applications
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="card border-0 bg-light-danger rounded h-100">
                              <div className="card-body p-4">
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-40px me-3">
                                      <div className="symbol-label bg-danger">
                                        <i className="ki-outline ki-cross text-inverse-danger"></i>
                                      </div>
                                    </div>
                                    <div>
                                      <span className="fs-6 text-gray-600">
                                        Rejected Applications
                                      </span>
                                      <div className="fs-4 fw-bold text-dark">
                                        {item.funnel.rejected.count}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-end">
                                    <span className="badge badge-danger fs-5 mb-2">
                                      {item.funnel.rejected.ratio}
                                    </span>
                                    <div className="text-gray-600 fs-7">
                                      of total curated
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center mt-4">
                                  <span className="bullet bullet-vertical h-35px bg-danger me-3"></span>
                                  <div className="text-gray-600 fs-7">
                                    {item.funnel.rejected.count} out of{" "}
                                    {item.funnel.rejected.total} applications
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-center mt-5">
                          <div className="text-center">
                            <span className="text-gray-500 fs-7 d-block mb-1">
                              Total Applications Processed
                            </span>
                            <span className="text-dark fs-3 fw-bolder">
                              {item.funnel.approved.total}
                            </span>
                          </div>
                          <div className="separator separator-dashed mx-4 w-150px"></div>
                          <div className="text-center">
                            <span className="text-gray-500 fs-7 d-block mb-1">
                              Average Approval Rate
                            </span>
                            <span className="text-primary fs-3 fw-bolder">
                              {item.funnel.approved.ratio}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Design4 = ({ mockData }) => {
  return (
    <div className="container-fluid p-6 bg-light ">
      <div className="mb-8">
        <h2 className="text-dark fw-bolder fs-1">Performance Dashboard</h2>
        <div className="d-flex align-items-center">
          <i className="ki-outline ki-chart-line fs-2 text-primary me-2"></i>
          <p className="text-gray-600 fs-5 mb-0">
            Sales funnel and conversion metrics overview
          </p>
        </div>
      </div>

      <div className="row g-5 flex-nowrap overflow-auto">
        {mockData.map((item, index) => (
          <div key={index} className="col-6 ">
            <div className="card shadow-sm border-0 h-100">
              {/* Card Header with Stats Summary */}
              <div className="card-header border-0 bg-white py-5">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div className="d-flex align-items-center">
                    <i className="ki-outline ki-building fs-1 text-primary me-4"></i>
                    <div>
                      <h3 className="card-title fw-bolder text-dark fs-2 mb-2">
                        {item.title}
                      </h3>
                      <span className="text-gray-400">
                        Overall Conversion Rate:{" "}
                      </span>
                      <span className="text-primary fw-bold fs-4">
                        {item.stats.convRatio}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Main Metrics Cards */}
              <div className="card-body pt-0">
                <div className="row g-4">
                  {/* Leads Card */}
                  <div className="col-md-6">
                    <div className="card border-0 bg-light-primary h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-abstract-26 fs-1 text-primary"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Total Leads
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.lead}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="bullet bullet-vertical h-40px bg-primary me-3"></span>
                            <div className="fs-6">
                              <span className="text-gray-500">First Stage</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Applications Card */}
                  <div className="col-md-6">
                    <div className="card border-0 bg-light-info h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-document fs-1 text-info"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Applications
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.applied}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="bullet bullet-vertical h-40px bg-info me-3"></span>
                              <div className="fs-6">
                                <span className="text-gray-500">
                                  Lead to App
                                </span>
                              </div>
                            </div>
                            <span className="badge badge-light-info fs-6">
                              {item.stats.leadToApp}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Approved Card */}
                  <div className="col-md-6">
                    <div className="card border-0 bg-light-success h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-check fs-1 text-success"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Approved
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.approved}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="bullet bullet-vertical h-40px bg-success me-3"></span>
                              <div className="fs-6">
                                <span className="text-gray-500">
                                  Approval Rate
                                </span>
                              </div>
                            </div>
                            <span className="badge badge-light-success fs-6">
                              {item.stats.curatedToApproved}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Sales Card */}
                  <div className="col-md-6">
                    <div className="card border-0 bg-light-warning h-100">
                      <div className="card-body p-5">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-4">
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-white">
                                <i className="ki-outline ki-dollar fs-1 text-warning"></i>
                              </div>
                            </div>
                            <div>
                              <div className="fs-5 text-gray-600 fw-semibold">
                                Tickets Sold
                              </div>
                              <div className="fs-2 fw-bold text-gray-800">
                                {item.ticketsSold}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="bullet bullet-vertical h-40px bg-warning me-3"></span>
                              <div className="fs-6">
                                <span className="text-gray-500">
                                  Close Rate
                                </span>
                              </div>
                            </div>
                            <span className="badge badge-light-warning fs-6">
                              {item.stats.approvedToSale}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Funnel Stats Redesigned with Total Curated */}
                <div className="row mt-8">
                  <div className="col-12">
                    <div className="card ">
                      <div className="card-body p-5">
                        <div className="d-flex justify-content-between align-items-center mb-5">
                          <div className="d-flex align-items-center">
                            <i className="ki-outline ki-funnel fs-2 me-2 text-primary"></i>
                            <h4 className="fw-bold text-dark mb-0">
                              Funnel Analysis
                            </h4>
                          </div>
                          <div className="bg-light-primary rounded px-4 py-2">
                            <span className="text-gray-600 fs-6 me-2">
                              Total Curated:
                            </span>
                            <span className="text-dark fw-bold fs-5">
                              {item.funnel.approved.total}
                            </span>
                          </div>
                        </div>
                        <div className="row g-5">
                          <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between rounded p-4 mb-4">
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-40px me-3">
                                  <div className="symbol-label bg-success">
                                    <i className="ki-outline ki-check text-inverse-success fs-1"></i>
                                  </div>
                                </div>
                                <div>
                                  <span className="fs-6 text-gray-600">
                                    Approved Applications
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <div className="fs-4 fw-bold text-dark me-2">
                                      {item.funnel.approved.count}
                                    </div>
                                    <span className="text-muted fs-7">
                                      of {item.funnel.approved.total}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <span className="badge badge-success fs-5">
                                {item.funnel.approved.ratio}
                              </span>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between rounded p-4">
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-40px me-3">
                                  <div className="symbol-label bg-danger">
                                    <i className="ki-outline ki-cross text-inverse-danger fs-1"></i>
                                  </div>
                                </div>
                                <div>
                                  <span className="fs-6 text-gray-600">
                                    Rejected Applications
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <div className="fs-4 fw-bold text-dark me-2">
                                      {item.funnel.rejected.count}
                                    </div>
                                    <span className="text-muted fs-7">
                                      of {item.funnel.rejected.total}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <span className="badge badge-danger fs-5">
                                {item.funnel.rejected.ratio}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Design5 = ({ mockData }) => {
  return (
    <div className="container-fluid p-4">
      {/* Header */}
      <div className="mb-4">
        <h2 className="text-dark fw-bold">Performance Dashboard</h2>
        <p className="text-muted">
          Sales funnel and conversion metrics overview
        </p>
      </div>

      {/* Main Cards Grid */}
      <div className="row g-4">
        {mockData.map((item, index) => (
          <div key={index} className="col-12">
            <div className="card shadow-sm">
              <div className="card-header bg-light">
                <h3 className="card-title fw-bold mb-0">{item.title}</h3>
              </div>

              {/* Key Metrics */}
              <div className="card-body">
                <div className="row g-4">
                  {/* Leads Card */}
                  <div className="col-md-3">
                    <div className="border rounded p-4 h-100 bg-light-primary">
                      <div className="d-flex align-items-center mb-2">
                        <i className="ki-outline ki-abstract-26 fs-2 text-primary me-2"></i>
                        <div className="fs-6 text-gray-600">Total Leads</div>
                      </div>
                      <div className="fs-2 fw-bold">{item.lead}</div>
                    </div>
                  </div>

                  {/* Applications Card */}
                  <div className="col-md-3">
                    <div className="border rounded p-4 h-100 bg-light-info">
                      <div className="d-flex align-items-center mb-2">
                        <i className="ki-outline ki-document fs-2 text-info me-2"></i>
                        <div className="fs-6 text-gray-600">Applications</div>
                      </div>
                      <div className="fs-2 fw-bold">{item.applied}</div>
                      <div className="text-info mt-2">
                        {item.stats.leadToApp} conversion
                      </div>
                    </div>
                  </div>

                  {/* Approved Card */}
                  <div className="col-md-3">
                    <div className="border rounded p-4 h-100 bg-light-success">
                      <div className="d-flex align-items-center mb-2">
                        <i className="ki-outline ki-check fs-2 text-success me-2"></i>
                        <div className="fs-6 text-gray-600">Approved</div>
                      </div>
                      <div className="fs-2 fw-bold">{item.approved}</div>
                      <div className="text-success mt-2">
                        {item.stats.curatedToApproved} approval rate
                      </div>
                    </div>
                  </div>

                  {/* Sales Card */}
                  <div className="col-md-3">
                    <div className="border rounded p-4 h-100 bg-light-warning">
                      <div className="d-flex align-items-center mb-2">
                        <i className="ki-outline ki-dollar fs-2 text-warning me-2"></i>
                        <div className="fs-6 text-gray-600">Tickets Sold</div>
                      </div>
                      <div className="fs-2 fw-bold">{item.ticketsSold}</div>
                      <div className="text-warning mt-2">
                        {item.stats.approvedToSale} close rate
                      </div>
                    </div>
                  </div>
                </div>

                {/* Funnel Stats */}
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="border rounded p-4">
                      <h4 className="mb-4">Funnel Analysis</h4>
                      <div className="progress" style={{ height: "25px" }}>
                        <div
                          className="progress-bar bg-success"
                          style={{ width: item.funnel.approved.ratio }}
                        >
                          Approved ({item.funnel.approved.ratio})
                        </div>
                        <div
                          className="progress-bar bg-danger"
                          style={{ width: item.funnel.rejected.ratio }}
                        >
                          Rejected ({item.funnel.rejected.ratio})
                        </div>
                      </div>

                      {/* Overall Conversion */}
                      <div className="mt-4 text-center">
                        <div className="fs-6 text-gray-600">
                          Overall Conversion Rate
                        </div>
                        <div className="fs-2 fw-bolder text-primary">
                          {item.stats.convRatio}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
const DashboardCard = ({
  title,
  lead,
  applied,
  approved,
  ticketsSold,
  stats,
  funnel,
}) => (
  <div className="col-md-6 mb-4">
    <div className="card shadow-sm h-100">
      <div className="card-header bg-primary text-white">
        <h5 className="mb-0">{title}</h5>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-between mb-2">
          <div>
            <i className="ki-outline ki-lead mr-2"></i>
            <span>Leads: {lead}</span>
          </div>
          <div>
            <i className="ki-outline ki-applied mr-2"></i>
            <span>Applied: {applied}</span>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <div>
            <i className="ki-outline ki-approved mr-2"></i>
            <span>Approved: {approved}</span>
          </div>
          <div>
            <i className="ki-outline ki-tickets mr-2"></i>
            <span>Tickets Sold: {ticketsSold}</span>
          </div>
        </div>

        <hr />

        <div className="mb-2">
          <h6>Stats</h6>
          <div className="d-flex justify-content-between">
            <span>Lead to App: {stats.leadToApp}</span>
            <span>Curated to Approved: {stats.curatedToApproved}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Approved to Sale: {stats.approvedToSale}</span>
            <span>Conversion Ratio: {stats.convRatio}</span>
          </div>
        </div>

        <hr />

        <div className="mb-2">
          <h6>Funnel</h6>
          <div className="d-flex justify-content-between">
            <div>
              <i className="ki-outline ki-approved mr-2"></i>
              Approved: {funnel.approved.count} / {funnel.approved.total} (
              {funnel.approved.ratio})
            </div>
            <div>
              <i className="ki-outline ki-rejected mr-2"></i>
              Rejected: {funnel.rejected.count} / {funnel.rejected.total} (
              {funnel.rejected.ratio})
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const MetricBox = ({ icon, label, value, color }) => (
  <div className={`card card-custom card-stretch bg-light-${color} border-0`}>
    <div className="card-body p-4">
      <div className="d-flex align-items-center mb-2">
        <span className={`text-${color} me-2`}>
          <i className={`${icon} fs-2x`}></i>
        </span>
        <span className="text-gray-600 fs-7">{label}</span>
      </div>
      <div className={`fw-bold fs-2 text-${color}`}>{value}</div>
    </div>
  </div>
);

const RatioBox = ({ label, value, trend }) => (
  <div className="card card-custom card-bordered shadow-sm bg-light">
    <div className="card-body p-4">
      <div className="text-gray-600 fs-7 mb-2 text-uppercase fw-semibold">
        {label}
      </div>
      <div className="d-flex align-items-center">
        <span className="fw-bold fs-2 text-dark me-2">{value}</span>
        <span
          className={`badge badge-light-${
            trend === "up" ? "success" : "danger"
          } p-2`}
          style={{
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i className={`ki-outline ki-arrow-${trend} fs-6`}></i>
        </span>
      </div>
    </div>
  </div>
);

const FunnelTable = ({ data }) => (
  <div className="card card-custom card-stretch bg-light border-0 shadow-sm rounded">
    {/* <div className="card-header border-0 bg-light py-3">
      <h3 className="card-title fw-bold text-dark">Funnel Quality</h3>
    </div> */}
    <div className="card-body p-0">
      <div className="table-responsive">
        <table className="table table-row-bordered table-row-dashed gy-4 gs-4">
          <thead>
            <tr className="fw-semibold text-gray-700 fs-7 text-uppercase">
              <th>Funnel Quality</th>
              <th className="text-end"># of Apps</th>
              <th className="text-end">Total Curated</th>
              <th className="text-end">Ratio</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([status, values]) => (
              <tr key={status}>
                <td>
                  <div className="d-flex align-items-center">
                    <i
                      className={`ki-outline ki-${
                        status === "approved" ? "shield-tick" : "shield-cross"
                      } fs-2 text-${
                        status === "approved" ? "success" : "danger"
                      } me-2`}
                    ></i>
                    <span
                      className={`text-${
                        status === "approved" ? "success" : "danger"
                      } fw-semibold`}
                    >
                      {formatLabel(status)}
                    </span>
                  </div>
                </td>
                <td className="text-end text-dark fw-bold">{values.count}</td>
                <td className="text-end text-muted">{values.total}</td>
                <td
                  className={`text-end fw-bold text-${
                    status === "approved" ? "success" : "danger"
                  } `}
                >
                  {values.ratio}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

const getIconForMetric = (metric) => {
  const icons = {
    lead: "ki-outline ki-profile-user",
    applied: "ki-outline ki-note-2",
    approved: "ki-outline ki-shield-tick",
    ticketsSold: "ki-outline ki-shopping-cart",
  };
  return icons[metric] || "";
};

const getColorForMetric = (metric) => {
  const colors = {
    lead: "primary",
    applied: "info",
    approved: "success",
    ticketsSold: "warning",
  };
  return colors[metric] || "";
};

const getTrendForRatio = (ratio, value) => {
  const trends = {
    leadToApp: value > 70 ? "up" : "down",
    curatedToApproved: value > 65 ? "up" : "down",
    approvedToSale: value > 80 ? "up" : "down",
    convRatio: value > 40 ? "up" : "down",
  };
  return trends[ratio] || "";
};

const formatLabel = (label) => {
  return label.replace(/([A-Z])/g, " $1").trim();
};

export default AdMetrics;
