import { Drawer, Button, Select, DatePicker, Checkbox } from "antd"; // Removed Checkbox as it's not needed

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { fetchData } from "../../../../config/service";
import dayjs from "dayjs";
import { Loader } from "../../../../components";

export default function PaymentDrawer({ show, statusList, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    status: null,
    score: null,
  });
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    if (data) {
      getPaymentData();
    }
  }, [data]);

  const getPaymentData = () => {
    setLoading(true);
    fetchData(`api/payment/booking-payment-list/`, "post", {
      user_id: data?.user?.uuid,
      edition_id: data?.edition,
    }).then((res) => {
      setLoading(false);
      if (res.success) {
        console.log(res, "res");
        setPaymentData(res?.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const formatDate = (dateString) => {
    return dateString
      ? dayjs.utc(dateString).format("MMM D, YYYY h:mm a")
      : "-";
  };

  const formatAmount = (amount, currency) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <>
      <Drawer
        title="Payment History"
        placement="right"
        onClose={onHide}
        visible={show}
        width={450}
        style={{ height: "100%", overflowY: "auto" }}
      >
        {loading ? (
          <Loader cols={1} />
        ) : (
          <>
            {paymentData?.length > 0 ? (
              <>
                {paymentData.map((payment, index) => (
                  <>
                    <div key={payment.payment_id} className={`mb-10 `}>
                      <div
                        className={`d-flex justify-content-between align-items-center p-5 rounded ${
                          payment.status_label === "Success"
                            ? "bg-light-success"
                            : payment.status_label === "Pending"
                            ? "bg-light-warning"
                            : "bg-light-danger"
                        }`}
                      >
                        <div className="mb-0 h1">
                          {formatAmount(payment.amount, payment.currency)}
                        </div>
                        <div
                          className={`badge badge-outline ${
                            payment.status_label === "Success"
                              ? "badge-success"
                              : payment.status_label === "Pending"
                              ? "badge-warning"
                              : "badge-danger"
                          }`}
                        >
                          {payment.status_label}
                        </div>
                      </div>

                      <div className="d-flex flex-column gap-3 mt-4">
                        <div className="d-flex justify-content-between border-bottom pb-2">
                          <span className="text-muted">Payment ID</span>
                          <span className="fw-semibold">
                            {payment.payment_id}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between border-bottom pb-2">
                          <span className="text-muted">Currency</span>
                          <span className="fw-semibold">
                            {payment.currency}
                          </span>
                        </div>

                        {/* <div className="d-flex justify-content-between border-bottom pb-2">
                        <span className="text-muted">Comment</span>
                        <span
                          className="fw-semibold text-end"
                          style={{ maxWidth: "70%" }}
                        >
                          {payment.comment}
                        </span>
                      </div> */}

                        <div className="d-flex justify-content-between">
                          <span className="text-muted">Last Updated</span>
                          <span className="fw-semibold">
                            {dayjs(payment.updated_at).format(
                              "DD-MM-YYYY h:mm A"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="separator separator-dashed my-10"></div>
                  </>
                ))}
              </>
            ) : (
              <>
                <div className="row g-5 mb-0 gx-md-10">
                  <div className="col-md-12 text-center">
                    <div className="mt-0">
                      <img
                        src="/media/no-data-found.png"
                        className="mw-25"
                        alt="No Data Found"
                      />
                    </div>
                    <h1 className="mt-4">No Data Found</h1>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Drawer>
    </>
  );
}
