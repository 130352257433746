import React, { useState } from "react";
import { FeedbackModal } from "./modals";

// SVG Components
const SmileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="yellow"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-smile"
  >
    <circle cx="12" cy="12" r="10" />
    <path d="M8 14s1.5 2 4 2 4-2 4-2" />
    <line x1="9" x2="9.01" y1="9" y2="9" />
    <line x1="15" x2="15.01" y1="9" y2="9" />
  </svg>
);

const MehIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="orange"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-meh"
  >
    <circle cx="12" cy="12" r="10" />
    <path d="M8 14h8" />
    <line x1="9" x2="9.01" y1="9" y2="9" />
    <line x1="15" x2="15.01" y1="9" y2="9" />
  </svg>
);

const FrownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="red"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-frown"
  >
    <circle cx="12" cy="12" r="10" />
    <path d="M16 16s-1.5-2-4-2-4 2-4 2" />
    <line x1="9" x2="9.01" y1="9" y2="9" />
    <line x1="15" x2="15.01" y1="9" y2="9" />
  </svg>
);

const projects = [
  {
    name: "BHX Beaches 18",
    status: "Ended 363 days back",
    pending: 14,
    achievement: 142,
    goal: 80,
    npsScore: 178,
    feedback: { positive: 106, neutral: 6, negative: 42 },
  },
  {
    name: "BHX Rome Adventure",
    status: "Ongoing",
    pending: 7,
    achievement: 95,
    goal: 85,
    npsScore: 150,
    feedback: { positive: 70, neutral: 10, negative: 10 },
  },
  {
    name: "BHX Berlin & Prague 3",
    status: "Ended 330 days back",
    pending: 3,
    achievement: 67,
    goal: 80,
    npsScore: 120,
    feedback: { positive: 90, neutral: 15, negative: 25 },
  },
];

const contactData = [
  {
    id: 1,
    name: "Dhaval P",
    time: "11:58 am / Aug 09",
    score: 3,
    bhkBall: "BHK Balli 8",
    age: 25,
    revenue: "",
    category: "",
    story: "",
    email: "dhaval@socialiate.net",
  },
  {
    id: 2,
    name: "Shashank R",
    time: "11:57 am / Aug 09",
    score: 10,
    bhkBall: "BHK Ball 8",
    age: 25,
    revenue: "",
    category: "",
    story: "",
    email: "shashank@socialiate.net",
  },
  {
    id: 3,
    name: "Shashank R",
    time: "11:56 am / Aug 09",
    score: 7,
    bhkBall: "BHK Ball 8",
    age: 25,
    revenue: "",
    category: "",
    story: "",
    email: "shashank@socialiate.net",
  },
];

export default function Feedback() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [isOpenModal, setIsOpenModal] = useState(false);

  // State to manage collapse for each contact
  const [collapseState, setCollapseState] = useState({});

  // Toggle collapse function
  const toggleCollapse = (contactId) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [contactId]: !prevState[contactId],
    }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabs = [
    { id: "tab1", label: "KPIs" },
    { id: "tab2", label: "Overview" },
    { id: "tab3", label: "Details" },
    { id: "tab4", label: "Feedback View", extraClass: "min-w-150px" },
  ];

  return (
    <>
      <div className="col-xxl-12">
        <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
          <div className="card-header ">
            {/* tab list */}
            <ul
              className="nav nav-pills nav-pills-custom position-relative mx-0 mb-0 nav-stretch"
              role="tablist"
            >
              {tabs.map((tab) => (
                <li
                  className="nav-item mx-0 p-0"
                  role="presentation"
                  key={tab.id}
                >
                  <div
                    className={`nav-link d-flex justify-content-center w-100 border-0 h-100 cursor-pointer ${
                      tab.extraClass || ""
                    } ${activeTab === tab.id ? "active" : ""}`}
                    data-bs-toggle="pill"
                    aria-selected={activeTab === tab.id}
                    role="tab"
                    onClick={() => handleTabClick(tab.id)}
                  >
                    <span className="nav-text text-gray-800 fw-bold fs-5 mb-0">
                      {tab.label}
                    </span>
                    <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-3px bg-primary rounded" />
                  </div>
                </li>
              ))}
            </ul>
            <div className="card-toolbar "></div>
          </div>

          <div className="card-body py-4">
            <div className="tab-content">
              {/* tab 1 */}
              <div
                className={`tab-pane fade ${
                  activeTab === "tab1" ? "active show" : ""
                }`}
                id="kt_list_widget_10_tab_1"
                role="tabpanel"
              >
                <div className="container mt-4 mb-10">
                  {projects.map((project, index) => (
                    <div
                      key={index}
                      className="border-bottom p-4 mb-5"
                      style={{
                        boxShadow:
                          "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h3 className="fw-bold fs-1">{project.name}</h3>
                        <span
                          className={`badge badge-light-${
                            project.status === "Ongoing" ? "success" : "danger"
                          } fs-6`}
                        >
                          {project.status}
                        </span>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <span className="me-2">
                              <i className="bi bi-play-fill text-primary fs-4" />
                            </span>
                            <span className="fw-semibold">
                              Achievement:
                              <span className="fw-bolder text-primary ms-1">
                                {project.achievement}
                              </span>
                              <span
                                className={`ms-1 fs-8 ${
                                  project.achievement > project.goal
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                (
                                {project.achievement > project.goal ? "+" : "-"}
                                {Math.abs(
                                  ((project.achievement - project.goal) /
                                    project.goal) *
                                    100
                                ).toFixed(2)}
                                %)
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <span className="me-2">
                              <i className="ki-outline ki-watch text-primary fs-4"></i>
                            </span>
                            <span
                              className="fw-semibold  mb-1 cursor-pointer"
                              onClick={() => setIsOpenModal(true)}
                            >
                              Pending:
                              <span className="text-primary fw-bold ms-1">
                                {project.pending}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <span className="fw-semibold me-2">NPS Score:</span>
                            <span className="text-primary fw-bold">
                              {project.npsScore}%
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3 mt-3">
                        <h5 className="fw-bold  text-gray-700 mb-2 fs-5">
                          Feedback Summery
                        </h5>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-between mb-1">
                            <span className="fs-7">Positive</span>
                            <span className="fs-7">
                              {project.feedback.positive}%
                            </span>
                          </div>
                          <div className="progress" style={{ height: "8px" }}>
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: `${project.feedback.positive}%`,
                              }}
                              aria-valuenow={project.feedback.positive}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-between mb-1">
                            <span className="fs-7">Neutral</span>
                            <span className="fs-7">
                              {project.feedback.neutral}%
                            </span>
                          </div>
                          <div className="progress" style={{ height: "8px" }}>
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{
                                width: `${project.feedback.neutral}%`,
                              }}
                              aria-valuenow={project.feedback.neutral}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-between mb-1">
                            <span className="fs-7">Negative</span>
                            <span className="fs-7">
                              {project.feedback.negative}%
                            </span>
                          </div>
                          <div className="progress" style={{ height: "8px" }}>
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{
                                width: `${project.feedback.negative}%`,
                              }}
                              aria-valuenow={project.feedback.negative}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="d-flex justify-content-end align-items-center mt-5">
                        <button
                          className="btn btn-primary"
                          onClick={() => setIsOpenModal(true)}
                        >
                          View Details
                        </button>
                      </div> */}
                    </div>
                  ))}
                </div>
              </div>

              {/* tab 2 */}
              <div
                className={`tab-pane fade ${
                  activeTab === "tab2" ? "active show" : ""
                }`}
                id="kt_list_widget_10_tab_2"
                role="tabpanel"
              >
                <div className="container mt-4">
                  {/* <div className="card mb-4">
                    <div className="card-body">
                      <h5 className="card-title text-gray-700">
                        NPS Score - 0%
                      </h5>
                      <div className="d-flex align-items-start justify-content-between">
                        <div className="d-flex justify-content-start gap-3 mt-3">
                          <div className="text-success">😃 Type 1</div>
                          <div className="text-warning">😐 Type m</div>
                          <div className="text-danger">😞 Type h</div>
                        </div>
                        <div>
                          <p className="text-primary fw-bold fs-3">
                            All Editions
                          </p>
                          <p>
                            Quality:{" "}
                            <span className="text-muted">(0 out of 0)</span>
                          </p>
                          <p>
                            Pending: <span className="text-muted">0</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="col-md-6">
                      <div className="fw-bold fs-2 my-4">Overview</div>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Rating:
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Overall vibe of Tribe:
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Rate your Facilitators:
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="fw-bold fs-2 my-4">Production</div>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Villa:
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Food Design:
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              Room:
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-md-6">
                      <div className="fw-bold fs-2 my-4">Programming</div>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              I got what I was looking for
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              I found a support system with my tribe
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              I gained new and interesting perspectives
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              I found opportunities
                              (ideas/suggestions/collaborations) to level up
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              I was able to break personal barriers
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              I was able look at problems in a different way
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              I got actionable insights for growth
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                          <tr>
                            <td className="text-gray-600 fw-semibold fs-5">
                              I have 3 meaningful potential handshakes
                            </td>
                            <td className="text-end fw-bold fs-4">0.0</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="fw-bold fs-2 mt-4">
                    What needs to be improved upon?
                  </div>
                  <table className="table rounded mt-4">
                    {/* <thead className="">
                      <tr>
                        <th className="fs-5 text-gray-700 fw-bold">Item</th>
                        <th className="text-end fs-5 text-gray-700 fw-bold">
                          Count
                        </th>
                      </tr>
                    </thead> */}

                    <tbody>
                      {[
                        { label: "Time Management", value: 0 },
                        { label: "Programme & Sessions", value: 0 },
                        { label: "Facilitator/s", value: 0 },
                        { label: "Curation of Residents", value: 0 },
                        { label: "Outdoor Experiences", value: 0 },
                        { label: "Food Quality", value: 0 },
                        { label: "Choice of Stay", value: 0 },
                        { label: "Rooms", value: 0 },
                        { label: "Property Staff", value: 0 },
                      ]?.map((item, index) => (
                        <tr
                          key={index}
                          style={{ borderBottom: "1px dashed #ccc" }}
                        >
                          <td className="text-gray-600 fw-semibold fs-5">
                            {item.label}
                          </td>
                          <td className="text-end fw-bold fs-4">
                            {item.value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* tab 3 */}
              <div
                className={`tab-pane fade ${
                  activeTab === "tab3" ? "active show" : ""
                }`}
                id="kt_list_widget_10_tab_3"
                role="tabpanel"
              >
                <div className="container">
                  {contactData.map((contact) => (
                    <div key={contact.id} className="card mb-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="d-flex justify-content-start align-items-start flex-wrap">
                              <div>
                                <div className="symbol symbol-40px me-0 me-md-5">
                                  <div className="symbol-label fs-2 fw-bold bg-light-danger text-danger">
                                    S
                                  </div>
                                </div>

                                {/* <img
                                  src="/api/placeholder/50/50"
                                  alt={contact.name}
                                  className="rounded-circle me-3"
                                  width="50"
                                  height="50"
                                /> */}
                              </div>
                              <div className="d-flex flex-column justify-content-between gap-5   mt-2 mt-md-0 align-items-start">
                                <div className="d-flex justify-content-between gap-15 align-items-start">
                                  <div>
                                    <h5 className="card-title mb-0">
                                      {contact.name}
                                    </h5>
                                    <small className="text-muted">
                                      {contact.time}
                                    </small>
                                  </div>
                                  <button
                                    className="btn btn-sm text-primary bg-light-primary fs-1 rounded-pill ms-10 ms-md-15"
                                    onClick={() => toggleCollapse(contact.id)}
                                  >
                                    {contact.score}
                                  </button>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className="btn btn-warning btn-sm"
                                      onClick={() => toggleCollapse(contact.id)}
                                    >
                                      Detractor
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3  mt-2 mt-md-0">
                            <p className="mb-0">
                              <strong>BHK Ball:</strong> {contact.bhkBall}
                            </p>
                            <p className="mb-0">
                              <strong>Age:</strong> {contact.age}
                            </p>
                            <p className="mb-0">
                              <strong>Revenue:</strong> {contact.revenue}
                            </p>
                          </div>

                          <div className="col-md-2 ">
                            <div>
                              <p className="mb-0">
                                <strong>Score:</strong> {contact.score}
                              </p>
                              <p className="mb-0">
                                <strong>Category:</strong> {contact.category}
                              </p>
                              <p className="mb-0">
                                <strong>Story:</strong> {contact.story}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-3  mt-4 mt-md-0">
                            <div>
                              <div className="d-flex  flex-column  justify-content-start gap-5 align-items-start">
                                <div className="d-flex justify-content-between align-items-center gap-15">
                                  <button className="btn btn-sm">
                                    <i className="bi bi-whatsapp text-success fs-2"></i>
                                  </button>

                                  <button className="btn btn-sm">
                                    <i className="bi bi-telephone text-primary fs-2"></i>
                                  </button>
                                </div>
                                <div className="text-muted">
                                  {contact.email}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Collapsible Content */}
                        <div
                          id={`collapseContent${contact.id}`}
                          className={`collapse mt-3 ${
                            collapseState[contact.id] ? "show" : ""
                          }`}
                        >
                          <div className="container mt-10">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-4">
                                  <div className="text-gray-700 fw-bold fs-2">
                                    Facilitators
                                  </div>
                                  <div className="text-muted fw-semibold">
                                    Rating:
                                  </div>
                                  <div className="text-muted fw-semibold">
                                    Improve:
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                {" "}
                                <div className="mb-4">
                                  <div className="text-gray-700 fw-bold fs-2">
                                    Production Ratings
                                  </div>
                                  <div className="text-muted fw-semibold">
                                    Villa:
                                  </div>
                                  <div className="text-muted fw-semibold">
                                    Food Design:
                                  </div>
                                  <div className="text-muted fw-semibold">
                                    Rooms:
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="mb-4">
                                <h4 className="text-gray-700 fw-bold fs-2">
                                  Programming
                                </h4>
                                <ul className="list-unstyled">
                                  <li className="text-muted  fw-semibold fs-5 mb-1">
                                    <span class="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                    I got what I was looking for
                                  </li>
                                  <li className="text-muted  fw-semibold fs-5 mb-1">
                                    <span class="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                    I found a support system with my tribe
                                  </li>
                                  <li className="text-muted  fw-semibold fs-5 mb-1">
                                    <span class="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                    I gained new and interesting perspectives
                                  </li>
                                  <li className="text-muted  fw-semibold fs-5 mb-1">
                                    <span class="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                    I found opportunities
                                    (ideas/suggestions/collaborations) to level
                                    up
                                  </li>
                                  <li className="text-muted  fw-semibold fs-5 mb-1">
                                    <span class="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                    I was able to break personal barriers
                                  </li>
                                  <li className="text-muted  fw-semibold fs-5 mb-1">
                                    <span class="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                    I was able to look at problems in a
                                    different way
                                  </li>
                                  <li className="text-muted  fw-semibold fs-5 mb-1">
                                    <span class="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                    I got actionable insights for growth
                                  </li>
                                  <li className="text-muted  fw-semibold fs-5 mb-1">
                                    <span class="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                    I have 3 meaningful potential handshakes
                                  </li>
                                  <li className="text-muted  fw-semibold fs-5 mb-1">
                                    <span class="bullet bullet-dot bg-primary me-2 mb-1 h-7px w-7px"></span>
                                    Overall Vibe of the tribe
                                  </li>
                                </ul>
                              </div>
                              <div className="mb-4">
                                <h4 className="text-gray-700 fw-bold fs-2">
                                  Subjective Questions
                                </h4>
                                <p className="text-muted fw-semibold fs-6">
                                  What did we do to wow you?
                                </p>
                                <p className="text-muted fw-semibold fs-6">
                                  Which of the following can be improved upon?
                                </p>
                                <p className="text-muted fw-semibold fs-6">
                                  Curation of Residents
                                </p>
                                <p className="text-muted fw-semibold fs-6">
                                  Anything else you'd like to share with us?
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* tab 4 */}
              <div
                className={`tab-pane fade ${
                  activeTab === "tab4" ? "active show" : ""
                }`}
                id="kt_list_widget_10_tab_4"
                role="tabpanel"
              >
                <label htmlFor="" className="fs-6 fw-bold mb-2 mt-4">
                  Feedback View Tab
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpenModal && (
        <FeedbackModal
          show={isOpenModal}
          onHide={() => {
            setIsOpenModal(false);
          }}
        />
      )}
    </>
  );
}
