import React from "react";

export default function BusinessMetrics() {
  return (
    <div>
      <div className="row mb-7">
        <div className="col-md-12">
          <div className="d-flex flex-wrap gap-4">
            {/* <div class="position-relative ">
                                          <i
                                              class="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"></i>
                                          <input type="text" data-kt-table-widget-4="search"
                                              class="form-control text-dark w-250px fs-7 ps-12 all-search"
                                              name="all_search" placeholder="Search Hotel here...">
                                      </div> */}
            <div className="position-relative ">
              <select
                className="form-select fs-7 min-w-175px"
                data-control="select2"
                data-placeholder="Select an option"
                data-hide-search="true"
              >
                <option />
                <option value={1}>This Week</option>
                <option value={1}>Last Week</option>
                <option value={1}>This Month</option>
                <option value={1}>Last Month</option>
                <option value={1}>This Quarter</option>
                <option value={1}>Last Quarter</option>
              </select>
            </div>
            <div className="position-relative ">
              <select
                className="form-select fs-7 min-w-175px"
                data-control="select2"
                data-placeholder="Select Edition"
                data-hide-search="true"
              >
                <option />
                <option value={1}>Prague</option>
                <option value={1}>Goa</option>
                <option value={1}>Amsterdam</option>
              </select>
            </div>
            <div className="position-relative ">
              <select
                className="form-select fs-7 min-w-150px"
                data-control="select2"
                data-placeholder="Select Category"
                data-hide-search="true"
              >
                <option />
                <option value={1}>BHX</option>
              </select>
            </div>
            <div className="position-relative mr-4">
              <i className="ki-outline ki-calendar-8 search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
              <input
                type="text"
                className="form-control text-dark w-250px fs-7 ps-14 "
                name="all_search"
                id="kt_daterangepicker_2"
                placeholder="Pick date rage"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row g-6 mb-6 g-xl-9 mb-xl-9">
        {/*begin::Followers*/}
        {/*begin::Col*/}
        <div className="col-md-12 col-xxl-12">
          <div className="card ">
            <div
              className="card-header collapsible cursor-pointer rotate border-0"
              data-bs-toggle="collapse"
              data-bs-target="#kt_docs_card_collapsible_analytics"
            >
              <h3 className="card-title fw-bold">Business Metric</h3>
              <div className="card-toolbar rotate-180">
                <i className="ki-duotone ki-down fs-1" />
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="row ">
                <div className="col-md-3">
                  <div className="d-flex align-items-center bg-light-warning rounded p-5 mb-5">
                    <i className="ki-outline ki-abstract-26 text-warning fs-1 me-5" />
                    <div className="flex-grow-1 me-2">
                      <a
                        href="#"
                        className="fw-bold text-gray-800 text-hover-primary fs-6"
                      >
                        New Apps
                      </a>
                    </div>
                    <span className="fw-bold text-warning py-0 fs-5">0</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center bg-light-warning rounded p-5 mb-5">
                    <i className="ki-outline ki-abstract-26 text-warning fs-1 me-5" />
                    <div className="flex-grow-1 me-2">
                      <a
                        href="#"
                        className="fw-bold text-gray-800 text-hover-primary fs-6"
                      >
                        Approved Apps
                      </a>
                    </div>
                    <span className="fw-bold text-warning py-0 fs-5">1</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center bg-light-warning rounded p-5 mb-5">
                    <i className="ki-outline ki-abstract-26 text-warning fs-1 me-5" />
                    <div className="flex-grow-1 me-2">
                      <a
                        href="#"
                        className="fw-bold text-gray-800 text-hover-primary fs-6"
                      >
                        Call Request Sent
                      </a>
                    </div>
                    <span className="fw-bold text-warning py-0 fs-5">2</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center bg-light-danger rounded p-5 ">
                    <i className="ki-outline ki-abstract-26 text-danger fs-1 me-5" />
                    <div className="flex-grow-1 me-2">
                      <a
                        href="#"
                        className="fw-bold text-gray-800 text-hover-primary fs-6"
                      >
                        Total Calls Done
                      </a>
                    </div>
                    <span className="fw-bold text-danger py-0 fs-5">0</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center bg-light-success rounded p-5">
                    <i className="ki-outline ki-abstract-26 text-success fs-1 me-5" />
                    <div className="flex-grow-1 me-2">
                      <a
                        href="#"
                        className="fw-bold text-gray-800 text-hover-primary fs-6"
                      >
                        Tickets Sold via Call
                      </a>
                    </div>
                    <span className="fw-bold text-success py-0 fs-5">0</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center bg-light-success rounded p-5">
                    <i className="ki-outline ki-questionnaire-tablet text-success fs-1 me-5" />
                    <div className="flex-grow-1 me-2">
                      <a
                        href="#"
                        className="fw-bold text-gray-800 text-hover-primary fs-6"
                      >
                        Tickets Sold via Platform
                      </a>
                    </div>
                    <span className="fw-bold text-success py-0 fs-5">0</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center bg-light-danger rounded p-5">
                    <i className="ki-outline ki-file-up text-danger fs-1 me-5" />
                    <div className="flex-grow-1 me-2">
                      <a
                        href="#"
                        className="fw-bold text-gray-800 text-hover-primary fs-6"
                      >
                        Future Editions
                      </a>
                    </div>
                    <span className="fw-bold text-danger py-0 fs-5">0</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center bg-light-success rounded p-5 ">
                    <i className="ki-outline ki-calendar-edit text-success fs-1 me-5" />
                    <div className="flex-grow-1 me-2">
                      <a
                        href="#"
                        className="fw-bold text-gray-800 text-hover-primary fs-6"
                      >
                        Tickets Sold <small>(Paid Date)</small>
                      </a>
                    </div>
                    <span className="fw-bold text-success py-0 fs-5">0</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="kt_docs_card_collapsible_analytics"
              className="collapse show"
            >
              <div
                className="card-footer p-5"
                style={{
                  backgroundColor: "#f7f7f7",
                  border: "5px solid #fff",
                  borderTop: "none",
                }}
              >
                <div className="row g-5 gap-0 card-analytics">
                  <div className="col ">
                    <div className="p-3 main-card">
                      <h3>0</h3>
                      <p className="mb-0">Edition Changes (Paid Users)</p>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="p-3 main-card">
                      <h3>1</h3>
                      <p className="mb-0">Approved Edition Changes</p>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="p-3 main-card">
                      <h3>0</h3>
                      <p className="mb-0">Total Future Edition Moves</p>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="p-3 main-card">
                      <h3>0</h3>
                      <p className="mb-0">EDX Moves</p>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="p-3 main-card">
                      <h3>1</h3>
                      <p className="mb-0">Call Req. Sent to Old Apps</p>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="p-3 main-card">
                      <h3>0</h3>
                      <p className="mb-0">Very Happy Calls</p>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="p-3 main-card">
                      <h3>0</h3>
                      <p className="mb-0">Call No Shows</p>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="p-3 main-card">
                      <h3>0</h3>
                      <p className="mb-0">Star Profiles Converted</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end::Col*/}
      </div>
      <div className="card mb-7">
        <div className="card-header ">
          <div className="card-title flex-column ">
            <h2 className="mb-1">Open Editions</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue
                id="flexCheckDefault"
              />
              <label
                className="form-check-label fs-6 text-gray-800 fw-bold"
                htmlFor="flexCheckDefault"
              >
                Show All Time
              </label>
            </div>
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate"
            data-bs-toggle="collapse"
            data-bs-target="#kt_docs_card_collapsible"
          >
            <div className=" rotate-180">
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div id="kt_docs_card_collapsible" className="collapse show">
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-125px">Name of Edition</th>
                    <th className="min-w-80px">Apps</th>
                    <th className="min-w-75px">Sold</th>
                    <th className="min-w-50px">FQ</th>
                    <th className="min-w-75px">Conv%</th>
                    <th className="min-w-90px">Days Left</th>
                    <th className="min-w-125px">Active Invites</th>
                    <th className="min-w-100px">Dead Invites</th>
                    <th className="min-w-100px">Uncurated</th>
                    <th className="min-w-100px">Drop-offs</th>
                    <th className="min-w-80px">Status</th>
                    {/* <th class="min-w-100px">Action</th> */}
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol  symbol-50px overflow-hidden me-3">
                          <img
                            src="assets/media/stock/600x600/img-1.jpg"
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::User details*/}
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-175px"
                          >
                            BHX Beaches 20
                          </a>
                          <div className="text-gray-600">Mar 15, 2024</div>
                        </div>
                      </div>
                    </td>
                    <td>1124</td>
                    <td>
                      <span className="fw-bolder">20</span>
                    </td>
                    <td>48.1%</td>
                    <td>1.8%</td>
                    <td>-59</td>
                    <td>0</td>
                    <td>530</td>
                    <td>0</td>
                    <td>0</td>
                    <td>
                      <span className="badge py-3 px-4 fs-7 badge-light-primary">
                        Open
                      </span>
                    </td>
                  </tr>
                  <tr className>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol  symbol-50px overflow-hidden me-3">
                          <img
                            src="assets/media/stock/600x600/img-1.jpg"
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::User details*/}
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-175px"
                          >
                            BHX Beaches 20
                          </a>
                          <div className="text-gray-600">Mar 15, 2024</div>
                        </div>
                      </div>
                    </td>
                    <td>1124</td>
                    <td>
                      <span className="fw-bolder">20</span>
                    </td>
                    <td>48.1%</td>
                    <td>1.8%</td>
                    <td>-59</td>
                    <td>0</td>
                    <td>530</td>
                    <td>0</td>
                    <td>0</td>
                    <td>
                      <span className="badge py-3 px-4 fs-7 badge-light-primary">
                        Open
                      </span>
                    </td>
                  </tr>
                  <tr className>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol  symbol-50px overflow-hidden me-3">
                          <img
                            src="assets/media/stock/600x600/img-1.jpg"
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::User details*/}
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-175px"
                          >
                            BHX Beaches 20
                          </a>
                          <div className="text-gray-600">Mar 15, 2024</div>
                        </div>
                      </div>
                    </td>
                    <td>1124</td>
                    <td>
                      <span className="fw-bolder">20</span>
                    </td>
                    <td>48.1%</td>
                    <td>1.8%</td>
                    <td>-59</td>
                    <td>0</td>
                    <td>530</td>
                    <td>0</td>
                    <td>0</td>
                    <td>
                      <span className="badge py-3 px-4 fs-7 badge-light-success">
                        Finished
                      </span>
                      <span
                        className="badge badge-outline badge-danger mt-1 cursor-pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_edition_red"
                      >
                        Edition in Red
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-7">
        <div className="card-header ">
          <div className="card-title flex-column ">
            <h2 className="mb-1">Edition Drop Offs</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue
                id="flexCheckDefault"
              />
              <label
                className="form-check-label fs-6 text-gray-800 fw-bold"
                htmlFor="flexCheckDefault"
              >
                Show All Time
              </label>
            </div>
            {/* <div class="ms-4">
                                                  <select name="" id="" class="form-select fs-7 min-w-150px">
                                                      <option value="">Open</option>
                                                      <option value="">Finished</option>
                                                      <option value="">Cancelled</option>
                                                      <option value="">Closed</option>
                                                      <option value="">Rescheduled</option>
                                                      <option value="">Future</option>
                                                  </select>
                                              </div> */}
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#kt_docs_card_collapsible"
          >
            <div className=" rotate-180">
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div id="kt_docs_card_collapsible" className="collapse ">
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-125px">Name of Edition</th>
                    <th className="min-w-80px">Moved to Future</th>
                    <th className="min-w-75px">Attended</th>
                    <th className="min-w-50px">Total Sold</th>
                    <th className="min-w-75px">Avg. Dropoff</th>
                    <th className="min-w-90px">Drop Percentage</th>
                    {/* <th class="min-w-100px">Action</th> */}
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol  symbol-50px overflow-hidden me-3">
                          <img
                            src="assets/media/stock/600x600/img-1.jpg"
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::User details*/}
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-175px"
                          >
                            BHX Beaches 20
                          </a>
                          <div className="text-gray-600">Mar 15, 2024</div>
                        </div>
                      </div>
                    </td>
                    <td>0</td>
                    <td>59</td>
                    <td>59</td>
                    <td>0</td>
                    <td>0.0%</td>
                  </tr>
                  <tr className>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol  symbol-50px overflow-hidden me-3">
                          <img
                            src="assets/media/stock/600x600/img-1.jpg"
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::User details*/}
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-175px"
                          >
                            BHX Beaches 20
                          </a>
                          <div className="text-gray-600">Mar 15, 2024</div>
                        </div>
                      </div>
                    </td>
                    <td>0</td>
                    <td>59</td>
                    <td>59</td>
                    <td>0</td>
                    <td>0.0%</td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-7">
        <div className="card-header ">
          <div className="card-title flex-column ">
            <h2 className="mb-1">Second Payments</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            {/* <div class="form-check">
                                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                  <label class="form-check-label fs-6 text-gray-800 fw-bold" for="flexCheckDefault">
                                                      Show All Time
                                                  </label>
                                              </div> */}
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Upcoming Editions</option>
              </select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#kt_docs_card_collapsible1"
          >
            <div className=" rotate-180">
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div id="kt_docs_card_collapsible1" className="collapse ">
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-125px">Name of Edition</th>
                    <th className="min-w-80px">Pending</th>
                    <th className="min-w-75px">Full Payments</th>
                    <th className="min-w-50px">Overdue</th>
                    <th className="min-w-75px">Due 0-30 Days</th>
                    <th className="min-w-75px">Due 31-60 Days</th>
                    <th className="min-w-75px">Due 61+ Days</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol  symbol-50px overflow-hidden me-3">
                          <img
                            src="assets/media/stock/600x600/img-1.jpg"
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::User details*/}
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-175px"
                          >
                            BHX Beaches 20
                          </a>
                          <div className="text-gray-600">Mar 15, 2024</div>
                        </div>
                      </div>
                    </td>
                    <td>8</td>
                    <td>4</td>
                    <td>
                      <span className="fw-bolder">8</span>
                    </td>
                    <td>8</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-7">
        <div className="card-header ">
          <div className="card-title flex-column ">
            <h2 className="mb-1">Edition Wise Move Outs</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            {/* <div class="form-check">
                                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                  <label class="form-check-label fs-6 text-gray-800 fw-bold" for="flexCheckDefault">
                                                      Show All Time
                                                  </label>
                                              </div> */}
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#kt_docs_card_collapsible2"
          >
            <div className=" rotate-180">
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div id="kt_docs_card_collapsible2" className="collapse ">
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-125px">Name of Edition</th>
                    <th className="min-w-100px">
                      Paid - Move outs vs Move Ins
                    </th>
                    <th className="min-w-100px">
                      Unpaid - Move outs vs Move Ins
                    </th>
                    <th className="min-w-100px">Date</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol  symbol-50px overflow-hidden me-3">
                          <img
                            src="assets/media/stock/600x600/img-1.jpg"
                            alt=""
                          />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::User details*/}
                        <div className="d-flex flex-column">
                          <a
                            href
                            className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 min-w-175px"
                          >
                            BHX Beaches 20
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span className="min-w-50px d-inline-flex">0</span>{" "}
                        <span className="me-0">0</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span className="min-w-50px d-inline-flex">1</span>{" "}
                        <span className="me-0">0</span>
                      </div>
                    </td>
                    <td>Mar 15, 2024</td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-7">
        <div className="card-header ">
          <div className="card-title flex-column ">
            <h2 className="mb-1">Revenue Breakdown of Applicants</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            {/* <div class="form-check">
                                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                  <label class="form-check-label fs-6 text-gray-800 fw-bold" for="flexCheckDefault">
                                                      Show All Time
                                                  </label>
                                              </div> */}
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#kt_docs_card_collapsible3"
          >
            <div className=" rotate-180">
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div id="kt_docs_card_collapsible3" className="collapse ">
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-125px">Name of Edition</th>
                    <th className="min-w-100px">Total</th>
                    <th className="min-w-100px">Business Owners</th>
                    <th className="min-w-100px">Salaried</th>
                    <th className="min-w-100px">Call Done Business</th>
                    <th className="min-w-100px">Call Done Salary</th>
                    <th className="min-w-100px">Score Median (Paid)</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <span className="fw-bolder">0 to 5L</span>
                    </td>
                    <td>
                      <div className>
                        <div>
                          Paid: <b>69</b>
                        </div>
                        <div>
                          Approved: <b>2455</b>
                        </div>
                        <div>
                          Applied: <b>5280</b>
                        </div>
                      </div>
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>4</td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-7">
        <div className="card-header ">
          <div className="card-title flex-column ">
            <h2 className="mb-1">Onboarding Calls vs Future Edition</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            {/* <div class="form-check">
                                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                  <label class="form-check-label fs-6 text-gray-800 fw-bold" for="flexCheckDefault">
                                                      Show All Time
                                                  </label>
                                              </div> */}
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#kt_docs_card_collapsible4"
          >
            <div className=" rotate-180">
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div id="kt_docs_card_collapsible4" className="collapse ">
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-125px">Name of Edition</th>
                    <th className="min-w-100px">Call Done On</th>
                    <th className="min-w-100px">Onboarding Questions</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <a
                        href
                        className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 "
                      >
                        Shalini
                      </a>
                    </td>
                    <td>May 5, 2022</td>
                    <td>Coming / Not Coming - ...</td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-7">
        <div className="card-header ">
          <div className="card-title flex-column ">
            <h2 className="mb-1">Curation Scores</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            {/* <div class="form-check">
                                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                  <label class="form-check-label fs-6 text-gray-800 fw-bold" for="flexCheckDefault">
                                                      Show All Time
                                                  </label>
                                              </div> */}
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#kt_docs_card_collapsible5"
          >
            <div className=" rotate-180">
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div id="kt_docs_card_collapsible5" className="collapse ">
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-100px">Scores</th>
                    <th className="min-w-100px">Approved</th>
                    <th className="min-w-100px">Paid</th>
                    <th className="min-w-100px">Sales via Calls</th>
                    <th className="min-w-100px">Ruchir</th>
                    <th className="min-w-100px">Ashita</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <a
                        href
                        className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 "
                      >
                        3.5
                      </a>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr className>
                    <td>
                      <a
                        href
                        className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 "
                      >
                        3.5
                      </a>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr className>
                    <td>
                      <a
                        href
                        className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 "
                      >
                        3.5
                      </a>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-7">
        <div className="card-header ">
          <div className="card-title flex-column ">
            <h2 className="mb-1">Field of Work</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            {/* <div class="form-check">
                                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                  <label class="form-check-label fs-6 text-gray-800 fw-bold" for="flexCheckDefault">
                                                      Show All Time
                                                  </label>
                                              </div> */}
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#kt_docs_card_collapsible6"
          >
            <div className=" rotate-180">
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div id="kt_docs_card_collapsible6" className="collapse ">
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-100px">Scores</th>
                    <th className="min-w-100px">Applied</th>
                    <th className="min-w-100px">Approved</th>
                    <th className="min-w-100px">Paid</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <a
                        href
                        className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 "
                      >
                        Design
                      </a>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr className>
                    <td>
                      <a
                        href
                        className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 "
                      >
                        Art &amp; Culture
                      </a>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-7">
        <div className="card-header ">
          <div className="card-title flex-column ">
            <h2 className="mb-1">Age</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            {/* <div class="form-check">
                                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                  <label class="form-check-label fs-6 text-gray-800 fw-bold" for="flexCheckDefault">
                                                      Show All Time
                                                  </label>
                                              </div> */}
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#kt_docs_card_collapsible7"
          >
            <div className=" rotate-180">
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div id="kt_docs_card_collapsible7" className="collapse ">
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-100px">Scores</th>
                    <th className="min-w-100px">Applied</th>
                    <th className="min-w-100px">Approved</th>
                    <th className="min-w-100px">Paid</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <a
                        href
                        className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 "
                      >
                        30 - 40
                      </a>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr className>
                    <td>
                      <a
                        href
                        className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 "
                      >
                        41 - 50
                      </a>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-7">
        <div className="card-header ">
          <div className="card-title flex-column ">
            <h2 className="mb-1">Monthly Second Payments</h2>
          </div>
          <div className="ms-auto d-flex align-items-center me-5 gap-4">
            {/* <div class="form-check">
                                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                  <label class="form-check-label fs-6 text-gray-800 fw-bold" for="flexCheckDefault">
                                                      Show All Time
                                                  </label>
                                              </div> */}
            <div className="ms-4">
              <select name id className="form-select fs-7 min-w-150px">
                <option value>Open</option>
                <option value>Finished</option>
                <option value>Cancelled</option>
                <option value>Closed</option>
                <option value>Rescheduled</option>
                <option value>Future</option>
              </select>
            </div>
          </div>
          <div
            className="card-toolbar collapsible cursor-pointer rotate collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#kt_docs_card_collapsible8"
          >
            <div className=" rotate-180">
              <i className="ki-duotone ki-down fs-1" />
            </div>
          </div>
        </div>
        <div id="kt_docs_card_collapsible8" className="collapse ">
          <div className="card-body">
            <div className="table-responsive tbl-sales">
              {/*begin::Table*/}
              <table
                className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 mb-0"
                id
              >
                <thead>
                  <tr className="fw-bolder text-gray-900 bg-light-primary">
                    {/* <th class="min-w-75px">Sr. No.</th> */}
                    <th className="min-w-100px">Months</th>
                    <th className="min-w-100px">Received</th>
                    <th className="min-w-100px">Due</th>
                    <th className="min-w-100px">Overdue</th>
                  </tr>
                </thead>
                <tbody className=" fw-semibold fs-7">
                  <tr className>
                    <td>
                      <a
                        href
                        className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 "
                      >
                        Fabruary
                      </a>
                    </td>
                    <td>32</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr className>
                    <td>
                      <a
                        href
                        className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6 "
                      >
                        March
                      </a>
                    </td>
                    <td>33</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
              {/*end::Table*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
