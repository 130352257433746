import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { Select, Tooltip } from "antd";
import { validateFields } from "../../../../components/validation";

export default function Project({ show, item, onHide, genderOptions }) {
  const [sending, setSending] = useState(false);
  const [projectData, setProjectData] = useState({
    projectName: "",
    description: "",
    summary: [],
    curationDeadlineHours: 0,
    finalPaymentDeadlineDays: 0,
    gender: null,
    score: null,
    travel_grant: 0,
  });

  const [tableData, setTableData] = useState([]); // State to store the table rows

  const [wordArray, setWordArray] = useState([]);
  const [images, setImages] = useState({
    heroImage: null,
    bannerImage: null,
  });
  const [imagePreviews, setImagePreviews] = useState({
    heroImagePreview: null,
    bannerImagePreview: null,
  });
  const [currentInput, setCurrentInput] = useState("");

  useEffect(() => {
    if (item?.id) {
      setProjectData({
        projectName: item?.name,
        description: item?.description,
        summary: item?.summary || [],
      });
      setTableData([...item?.project_grants] || []);

      setImages({
        heroImage: item?.hero_image,
        bannerImage: item?.banner_image,
      });

      setImagePreviews({
        heroImagePreview: item?.hero_image,
        bannerImagePreview: item?.banner_image,
      });
    } else {
      setTableData([]);
      setProjectData({
        projectName: "",
        description: "",
        summary: [],
        curationDeadlineHours: 0,
        finalPaymentDeadlineDays: 0,
        gender: null,
        score: null,
        travel_grant: 0,
      });
      setImages({
        heroImage: null,
        bannerImage: null,
      });
      setImagePreviews({
        heroImagePreview: null,
        bannerImagePreview: null,
      });
    }
  }, [item]);
  const validationRules = {
    projectName: {
      required: true,
    },
    description: {
      required: true,
      maxLength: 100,
    },
    summary: {
      required: true,
    },
  };

  const imagesValidationRules = {
    heroImage: {
      required: true,
      maxLength: 500,
    },
    bannerImage: {
      required: true,
      maxLength: 500,
    },
  };

  const onSubmit = () => {
    const { heroImage, bannerImage } = images || {};
    const { projectName, description, summary, gender, score, travel_grant } =
      projectData || {};

    if (
      !heroImage ||
      !bannerImage ||
      !projectName ||
      !description ||
      (!currentInput && summary.length === 0) || // Change here to check summary correctly
      (tableData.length === 0 && (!gender || !score || !travel_grant)) // Check gender, score, and travel_grant if tableData is empty
    ) {
      toast.error(
        !heroImage
          ? "Hero Image is required!"
          : !bannerImage
          ? "Banner Image is required!"
          : !projectName
          ? "Project Name is required!"
          : !description
          ? "Description is required!"
          : !currentInput && summary.length === 0 // Change here to check summary correctly
          ? "Summary is required!"
          : tableData.length === 0 && !gender // Add message for gender if tableData is empty
          ? "Gender is required!"
          : tableData.length === 0 && !score // Add message for score if tableData is empty
          ? "Score is required!"
          : tableData.length === 0 && !travel_grant // Add message for travel_grant if tableData is empty
          ? "Travel Grant is required!"
          : ""
      );
      return;
    }

    if (projectName.length > 64) {
      toast.error("Project Name cannot exceed 64 characters!");
      return;
    }

    if (description.length > 500) {
      toast.error("Description cannot exceed 500 characters!");
      return;
    }

    setSending(true);

    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const hasSummeryChanged = () => {
      // Check if both summaries are defined and are arrays
      if (
        !Array.isArray(projectData?.summary) ||
        !Array.isArray(item?.summary)
      ) {
        return true; // If one of them is not an array, we assume they are different
      }

      // Check if both arrays have the same length
      if (projectData.summary.length !== item.summary.length) {
        return true;
      }

      // Check if every element in the array is the same
      return projectData.summary.some(
        (value, index) => value !== item.summary[index]
      );
    };

    const summerInputValue = currentInput.trim() !== "" && [
      currentInput,
      ...(projectData?.summary || []),
    ];

    const summeryActualValue =
      summerInputValue.length > 0
        ? { summary: summerInputValue }
        : hasSummeryChanged() && {
            summary: projectData?.summary || [],
          };

    const hasProjectData =
      projectData?.travel_grant && projectData?.gender && projectData?.score;

    const pgValue = item?.id
      ? hasProjectData
        ? [
            {
              gender: projectData.gender,
              score: projectData.score,
              travel_grant: projectData.travel_grant,
            },
            ...tableData, // Assuming you want to spread an array or object here]
          ]
        : [...tableData]
      : hasProjectData
      ? [
          {
            gender: projectData.gender,
            score: projectData.score,
            travel_grant: projectData.travel_grant,
          },
        ]
      : [...tableData];

    const body = {
      ...(projectData?.projectName !== item?.name && {
        name: projectData?.projectName,
      }),
      ...(projectData?.description !== item?.description && {
        description: projectData?.description,
      }),
      ...(images?.heroImage && isURL(images?.heroImage)
        ? {}
        : { hero_image: images?.heroImage || "" }),
      ...(images?.bannerImage && isURL(images?.bannerImage)
        ? {}
        : { banner_image: images?.bannerImage || "" }),

      ...summeryActualValue,
      project_grants: pgValue,
    };

    // console.log(body, "body");
    let api = item?.id ? `api/project/${item?.id}/` : "api/project/";
    let method = item?.id ? "put" : "post";

    fetchData(api, method, body, "", true).then((res) => {
      setSending(false);
      if (res.success) {
        toast.success("Success");
        setCurrentInput("");
        onHide();
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleImagesChange = (e, imageType) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;

          // Define dimensions for the banner image only
          const requiredDimensions = {
            bannerImage: { width: 640, height: 300 },
          };

          // Check dimensions based on imageType
          const requiredWidth = requiredDimensions[imageType]?.width;
          const requiredHeight = requiredDimensions[imageType]?.height;

          if (
            imageType === "bannerImage" &&
            (width !== requiredWidth || height !== requiredHeight)
          ) {
            toast.error("Banner image dimensions must be 640x300px");

            // handleRemoveImages(imageType);
          } else {
            // If dimensions are correct or imageType is not "bannerImage", update the state
            setImages((prevImages) => ({
              ...prevImages,
              [imageType]: file,
            }));

            setImagePreviews((prevImagePreviews) => ({
              ...prevImagePreviews,
              [`${imageType}Preview`]: reader.result,
            }));
          }
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImages = (imageType) => {
    setImages((prevImages) => ({
      ...prevImages,
      [imageType]: null,
    }));

    setImagePreviews((prevImagePreviews) => ({
      ...prevImagePreviews,
      [`${imageType}Preview`]: null,
    }));
  };

  const handleKeyPress = (e) => {
    // if (e.key === ".") {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of Enter (new line)

      // Add the current input to the summary array
      const updatedDescriptions = [currentInput, ...projectData.summary];

      setProjectData((prevData) => ({
        ...prevData,
        summary: updatedDescriptions,
      }));

      setCurrentInput("");
    }
  };

  const removeSummary = (index) => {
    // Create a new array excluding the description at the specified index
    const updatedDescriptions = projectData?.summary.filter(
      (_, i) => i !== index
    );
    // Update the state with the new array
    setProjectData((prevData) => ({
      ...prevData,
      summary: updatedDescriptions,
    }));
  };

  const replaceFirstWord = (desc) => {
    const words = desc.split(" ");

    if (words.length > 0) {
      words[0] = `${"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0" + words[0]}`;
    }

    return words.join(" ");
  };

  const handleAddToTable = () => {
    const { gender, score, travel_grant } = projectData;
    if (gender && score && travel_grant) {
      setTableData([...tableData, { gender, score, travel_grant }]);
      setProjectData({
        ...projectData,
        gender: null,
        score: null,
        travel_grant: 0,
      });
    }
  };

  const handleRemoveFromTable = (index) => {
    const updatedTableData = tableData.filter((_, i) => i !== index);
    setTableData(updatedTableData);
  };

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {item?.id ? "Edit Project" : "Add New Project"}
        </h3>
      </Modal.Header>
      <div className="modal-body pb-7 pt-5 px-lg-10">
        <div
          className="scroll-y pe-7 ps-3"
          id="kt_modal_add_customer_scroll"
          style={{
            height: "430px",
          }}
        >
          <div className="row g-5 mb-5 align-items-center">
            <div className="col-md-6 fv-row mt-10">
              <label htmlFor className="fs-6 fw-bold mb-2 required">
                Hero Image <small className="text-muted">(For Icon)</small>
              </label>

              {imagePreviews?.heroImagePreview ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    src={imagePreviews?.heroImagePreview}
                    alt="Profile Preview"
                    style={{
                      width: "150px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      border: "2px solid #ccc",
                    }}
                  />
                  <i
                    title="Remove Image"
                    className="ki-outline ki-cross fs-2"
                    onClick={() => handleRemoveImages("heroImage")}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      cursor: "pointer",
                      zIndex: 2,
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ) : (
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="form-control fs-7"
                  onChange={(e) => handleImagesChange(e, "heroImage")}
                />
              )}
            </div>

            <div className="col-md-6 fv-row  mt-10">
              <label htmlFor className="fs-6 fw-bold mb-2 required">
                Banner Image
              </label>
              {imagePreviews?.bannerImagePreview ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    src={imagePreviews?.bannerImagePreview}
                    alt="Profile Preview"
                    style={{
                      width: "150px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      border: "2px solid #ccc",
                    }}
                  />
                  <i
                    title="Remove Image"
                    className="ki-outline ki-cross fs-2 "
                    onClick={() => handleRemoveImages("bannerImage")}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      cursor: "pointer",
                      zIndex: 2,
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ) : (
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="form-control fs-7"
                  onChange={(e) => handleImagesChange(e, "bannerImage")}
                />
              )}
            </div>

            <div className="col-md-6 fv-row  mt-10">
              <label
                htmlFor="curationDeadline"
                className="fs-6 fw-bold mb-2 required"
              >
                Curation Deadline (Number of Hours)
              </label>
              <input
                type="number"
                className="form-control fs-7"
                id="curationDeadline"
                value={projectData.curationDeadlineHours}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    curationDeadlineHours: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-6 fv-row  mt-10">
              <label
                htmlFor="finalPaymentDeadline"
                className="fs-6 fw-bold mb-2 required"
              >
                Final Payment Deadline (Enter Days)
              </label>
              <input
                type="number"
                className="form-control fs-7"
                id="finalPaymentDeadline"
                value={projectData.finalPaymentDeadlineDays}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    finalPaymentDeadlineDays: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-12 fv-row mt-10">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Name of Project
              </label>
              <input
                type="text"
                className="form-control fs-7"
                value={projectData?.projectName || ""}
                onChange={(e) => {
                  const common_data = {
                    ...projectData,
                    ["projectName"]: e.target.value,
                  };
                  setProjectData(common_data);
                }}
                placeholder="Enter Project Name"
              />
            </div>

            <div className="col-md-12 fv-row ">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Description
              </label>
              <textarea
                name
                className="form-control fs-7"
                id
                cols={30}
                rows={3}
                value={projectData?.description || ""}
                onChange={(e) => {
                  const common_data = {
                    ...projectData,
                    ["description"]: e.target.value,
                  };
                  setProjectData(common_data);
                }}
                placeholder="Enter Project Description"
              />
            </div>

            <div className="col-md-12 fv-row">
              <Tooltip title="Click the 'Enter' key to add New Paragraph">
                <label htmlFor="summary" className="fs-6 fw-bold mb-2 required">
                  Summary
                </label>
              </Tooltip>
              <textarea
                name="summary"
                className="form-control fs-7"
                placeholder="Enter Project Summary"
                id="summary"
                cols={30}
                rows={3}
                value={currentInput}
                onChange={(e) => setCurrentInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (currentInput.trim() !== "") {
                      handleKeyPress(e);
                    } else {
                      toast.error(
                        "Please fill in the field with meaningful input"
                      );
                    }
                  }
                }}
              />
              {projectData?.summary.length > 0 && (
                <>
                  <div>
                    <label htmlFor="description" className="fs-6 fw-bold my-4">
                      Summary Preview
                    </label>

                    {projectData?.summary.length > 0 &&
                      projectData.summary.map((desc, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center mb-5"
                        >
                          <div className="bullet text-primary me-2"></div>

                          <div className="text-gray-800 fs-4 ms-2">
                            {replaceFirstWord(desc)}
                          </div>

                          <Tooltip title="Remove Paragraph">
                            <div
                              className="ms-2 mt-2 cursor-pointer"
                              onClick={() => removeSummary(index)}
                            >
                              <i className="ki-outline fs-2 ki-minus-square text-danger"></i>
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>

            <div className="col-md-12 fv-row">
              <div className="fs-5 fw-bold mb-2">Project Grants:</div>
            </div>

            <div className="col-md-5 fv-row">
              <label htmlFor="gender" className="fs-6 fw-bold mb-2 required">
                Gender
              </label>
              <Select
                style={{ width: "100%" }}
                id="gender"
                allowClear
                value={projectData.gender}
                placeholder="Select Gender"
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    gender: e,
                  })
                }
                options={genderOptions}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
              ></Select>
            </div>

            <div className="col-md-3 fv-row">
              <label htmlFor="score" className="fs-6 fw-bold mb-2 required">
                Score
              </label>
              <Select
                style={{ width: "100%" }}
                id="score"
                allowClear
                placeholder="Select Score"
                value={projectData.score}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    score: e,
                  })
                }
                options={[
                  { value: 1, label: "1" },
                  { value: 2, label: "2" },
                  { value: 3, label: "3" },
                  { value: 3.5, label: "3.5" },
                  { value: 4, label: "4" },
                  { value: 4.5, label: "4.5" },
                  { value: 5, label: "5" },
                ]}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
              ></Select>
            </div>

            <div className="col-md-3 fv-row">
              <label
                htmlFor="travel_grant"
                className="fs-6 fw-bold mb-2 required"
              >
                Travel Grant
              </label>
              <input
                type="number"
                className="form-control fs-7"
                id="travel_grant"
                value={projectData.travel_grant}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    travel_grant: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-md-1 fv-row ">
              <Tooltip title="Add New Project Grant">
                <div
                  className="ms-2 cursor-pointer mt-7"
                  onClick={handleAddToTable}
                >
                  <i className="ki-outline fs-1 ki-plus-square text-success"></i>
                </div>
              </Tooltip>
            </div>

            {tableData?.length > 0 && (
              <>
                {" "}
                <div className="col-md-12 fv-row mt-5">
                  <div className="table-responsive tbl-sales mt-5">
                    {/*begin::Table*/}
                    <div className="table-responsive tbl-sales">
                      <table className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d">
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-light-primary">
                            <th className="min-w-150px">Gender</th>
                            <th className="min-w-150px">Score</th>
                            <th className="min-w-150px">Travel Grant</th>
                            <th className="min-w-150px">Actions</th>
                          </tr>
                        </thead>
                        <tbody className="fw-semibold fs-7">
                          {tableData.map((row, index) => (
                            <tr key={index}>
                              <td>
                                {genderOptions.find(
                                  (option) => option.value === row.gender
                                )?.label || "-"}
                              </td>
                              <td>{row.score}</td>
                              <td>{row.travel_grant}</td>
                              <td>
                                <Tooltip
                                  title="Remove Project Grant"
                                  placement="left"
                                >
                                  <div
                                    className="ms-2 cursor-pointer"
                                    onClick={() => handleRemoveFromTable(index)}
                                  >
                                    <i className="ki-outline fs-1 ki-minus-square text-danger"></i>
                                  </div>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/*end::Table*/}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={onSubmit}
          disabled={sending}
        >
          {sending ? (
            <span>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </Modal>
  );
}
