import React, { useEffect, useRef } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Layout from "./pages/layout";
import { handleLogout, setUser } from "./redux/slice";
import { fetchData } from "./config/service";
import { ConfigProvider } from "antd";
import { authRoutes, routes } from "./routes";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import UTC plugin
import "react-quill/dist/quill.snow.css"; // Import the CSS for Quill

// Extend dayjs with the UTC plugin
dayjs.extend(utc);

function App() {
  const localStorageBackupRef = useRef({}); // Using a ref to keep track of backup data
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  // useEffect(() => {
  //   getLocalData();
  // }, []);

  // useEffect(() => {
  //   if (user?.uuid) {
  //     getUser();
  //   }
  // }, [user?.uuid]);

  useEffect(() => {
    // Backup local storage data when the component mounts
    // Prevent local storage to modify
    // backupLocalStorage();

    // Check if token is present in localStorage
    const token = localStorage.getItem("token");

    const route = routes.find((item) => {
      // Check if the current path starts with the route path (ignoring dynamic parts)
      const routeBasePath = item.path.split("/:")[0]; // Get the base path before any dynamic segment
      return location.pathname.startsWith(routeBasePath);
    });

    // If no token and the route has a layout, log out the user and redirect to login
    if (!token && route?.layout) {
      dispatch(handleLogout());
      navigate("/login");
      return;
    }

    // Handle redirection based on the user's state and route configuration
    if (user) {
      if (!route?.layout) {
        navigate("/");
      } else if (!route) {
        navigate(location.pathname);
      }
    } else {
      if (route?.layout || !route) {
        navigate("/login");
      }
    }
  }, [location, user]);

  const getUser = () => {
    fetchData(`api/accounts/${user?.uuid}/`).then((res) => {
      if (res.success) {
        dispatch(setUser({ ...user, ...res }));
      }
    });
  };

  const getLocalData = () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      dispatch(setUser(user));
    } catch (error) {
      console.log(error);

      dispatch(setUser(""));
    }
  };

  const backupLocalStorage = () => {
    const backup = {};
    for (const key of Object.keys(localStorage)) {
      backup[key] = localStorage.getItem(key);
    }
    localStorageBackupRef.current = backup; // Store backup in ref
  };

  const restoreLocalStorage = () => {
    const backup = localStorageBackupRef.current;
    for (const [key, value] of Object.entries(backup)) {
      localStorage.setItem(key, value);
    }
  };

  const handleStorageChange = (e) => {
    console.log(e, "e");

    console.log(e.url, "url");
    e.stopPropagation();
    if (e.url === window.location.href) {
      if (!e.key) {
        restoreLocalStorage();
        // window.location.href = "/login";
        // clearLocalData();
      } else {
        localStorage.setItem(e.key, e.oldValue);
      }
    }
    if (!localStorage.getItem("token") || !localStorage.getItem("user")) {
      restoreLocalStorage();

      // window.location.href = "/login";
      // clearLocalData();
    }
  };

  // Prevent local storage to modify
  useEffect(() => {
    const storageEventHandler = (e) => {
      handleStorageChange(e);
    };

    window.addEventListener("storage", storageEventHandler);

    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#7843e6",
          fontFamily: `Inter, Helvetica, "sans-serif"`,
        },
      }}
    >
      <div className="App">
        <Toaster />
        <Routes>
          {routes.map((item, i) => {
            if (!item.layout) {
              return (
                <Route
                  path={item.path}
                  element={
                    item.layout ? <Layout>{item.element}</Layout> : item.element
                  }
                  key={i}
                />
              );
            }
          })}

          {localStorage.getItem("token") &&
            routes.map((item, i) => {
              if ((user && item.layout) || (!user && !item.layout)) {
                return (
                  <Route
                    path={item.path}
                    element={
                      item.layout ? (
                        <Layout>{item.element}</Layout>
                      ) : (
                        item.element
                      )
                    }
                    key={i}
                  />
                );
              }
            })}

          <Route
            path="*"
            element={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  textAlign: "center",
                  backgroundColor: "#f0f0f0",
                }}
              >
                <h1
                  style={{
                    fontSize: "8rem",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  404
                </h1>
              </div>
            }
          ></Route>
        </Routes>
        {/* </Providers> */}
      </div>
    </ConfigProvider>
  );
}

export default App;
