import React from "react";

const Visa = () => {
  return (
    <div className="card card-flush mb-6 mb-xl-9">
      <div className="card-header">
        <div className="card-title flex-column">
          <h2 className="mb-1">Visa</h2>
        </div>

        <div className="card-toolbar"></div>
      </div>

      <div className="card-body p-9 pt-2">
        <div className=" row g-5 mb-0 gx-md-10">
          <div className="col-md-6">
            <div className="mt-0">
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Add Visa Fee
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Generate Hotel Vouchers
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visa;
