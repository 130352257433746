import dayjs from "dayjs";
import React from "react";

const Booking = ({ editionData }) => {
  return (
    <div className="card card-flush mb-6 mb-xl-9">
      <div className="card-header">
        <div className="card-title flex-column">
          <h2 className="mb-1">Booking</h2>
        </div>

        <div className="card-toolbar"></div>
      </div>

      <div className="card-body p-9 pt-2">
        <div className=" row g-5 mb-5 gx-md-10">
          <div className="col-md-6">
            <div className="mt-0">
              <div className>
                <div className="mb-4 fs-6">
                  <span className="fw-bold">Booking ID:</span> {editionData?.id}
                </div>
                <div className="mb-4 fs-6">
                  <span className="fw-bold">Start Date:</span>{" "}
                  {editionData?.edition_details?.start_date
                    ? dayjs(editionData?.edition_details?.start_date).format(
                        "DD-MM-YYYY h:mm A"
                      )
                    : "-"}
                </div>
                <div className="mb-2 fs-6">
                  <span className="fw-bold">End Date:</span>{" "}
                  {editionData?.edition_details?.start_date
                    ? dayjs(editionData?.edition_details?.end_date).format(
                        "DD-MM-YYYY h:mm A"
                      )
                    : "-"}
                </div>
              </div>
            </div>
            <div className="mt-10">
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Change Edition
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Link Booking to Application #
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Reset Booking Dates
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Change Payment Status
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold text-danger">
                  Delete Booking
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h4 className="mb-8">Status Markers</h4>
            <div className="d-flex flex-stack">
              <div className="fs-6">Call request sent on:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Call at:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Followup on:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Followuped on:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Payment link sent on:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Paid on:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
          </div>
        </div>

        <div className="separator separator-dashed my-10"></div>

        <div className=" row g-5 mb-0 gx-md-10">
          <div className="col-md-6">
            <div className="bg-light-primary p-4 px-5 rounded mb-5 bdr-left-user">
              <div className="d-flex align-items-center flex-stack">
                <div className="fs-5 fw-bold">Total Fee:</div>
                <div className="fs-4 fw-bolder text-primary">-</div>
              </div>
              <div className="separator separator-dashed my-3 " />
              <div className=" mt-1">
                <small>
                  Visible to User = Fee for Edition + Dynamic Pricing (if any) -
                  Grant + Reschedule Fees (if any) + Visa Fee
                </small>
              </div>
            </div>
            <div className="bg-light-primary p-4 px-5 rounded bdr-left-user">
              <div className="d-flex align-items-center flex-stack">
                <div className="fs-5 fw-bold">Pending Fee:</div>
                <div className="fs-4 fw-bolder text-primary">-</div>
              </div>
              <div className="separator separator-dashed my-3 " />
              <div className=" mt-1">
                <small>Due Date: -</small>
              </div>
            </div>
            <div className="mt-10">
              <h4 className="mb-8">BHX Controls</h4>
              {/* <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Add First Payment
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Add Second Payment
                </a>
              </div>
              <div className="mb-10">
                <a href className="fs-6 fw-semibold">
                  Add Third Payment
                </a>
              </div> */}
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Add / Edit Reschedule Fees
                </a>
              </div>
              <div className="mb-10">
                <a href className="fs-6 fw-semibold">
                  Add Room Addon
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Create Invoice for User
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold text-danger">
                  Give a Refund (Razorpay Connected)
                </a>
              </div>
              <div className="mb-3">
                <a href className="fs-6 fw-semibold">
                  Find New Edition Fee
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h4 className="mb-8">Breakdown</h4>
            <div className="d-flex flex-stack">
              <div className="fs-6">Edition Fee:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Grant:</div>
              {/* <div className="fs-6 fw-bolder">₹10,000</div> */}
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Dynamic Pricing:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Reschedule Fee:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack">
              <div className="fs-6">Fee for User:</div>
              <div className="fs-6 fw-bolder">-</div>
            </div>
            <div className="separator separator-dashed my-4 " />
            <div className="d-flex flex-stack align-items-start">
              <div className="fs-6">
                <div className="mt-2"> Visa Fee:</div>
                <div className="mt-2">
                  <small className="text-danger fs-9">
                    Visa Fee has not been added to User Fee yet. <br />
                    This payment could be pending. Please recheck.
                  </small>
                </div>
              </div>
              <div className="fs-6 fw-bolder">
                <div className="position-relative">
                  <span className="position-absolute top-50 translate-middle-y ms-5">
                    ₹
                  </span>
                  <input
                    type="text"
                    className="border-0 form-control ps-7 form-control-sm w-150px fs-6 text-end text-dark"
                    placeholder="Enter Vise Fee"
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <h4 className="mb-8">Transaction Status</h4>
              <div className>
                <div className="mb-2 fs-6">
                  <span className="fw-bold">First Payment Status:</span> -
                </div>
                <div className="mb-2 fs-6">
                  <span className="fw-bold">Made on:</span> -
                </div>
                <div className="mb-2 fs-6">
                  <span className="fw-bold">Second Payment Status:</span> -
                </div>
                <div className="mb-2 fs-6">
                  <span className="fw-bold">Made on:</span> -
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
