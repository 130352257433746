import React, { useEffect, useState } from "react";
import { LIMITS, routesList } from "../../config";
import { Link } from "react-router-dom";
import { Loader, Pagination } from "../../components";
import { useSelector } from "react-redux";
import { fetchData } from "../../config/service";
import { CustomerModal } from "./modals";
import { Checkbox, Select } from "antd";
import circularProgressBar from "../../components/CirculerProgressBar";
import toast from "react-hot-toast";
import FilterDrawer from "./drawer/customer-filter";

export default function Customers() {
  const user = useSelector((state) => state.user);

  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [user_data, set_user_data] = useState({});
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [editionsList, setEditionsList] = useState([]);

  const [filterData, setFilterData] = useState({
    // status: null,
    // score: null,
    // edition: null,
    // debouncedSearchTerm: searchTerm,
    // all_users: null,

    all_users: 0,
    has_booking: 0,
    status: null,
    second_payment_status: null,
    visa_status: null,
    payment_status: null,
    flight_status: null,
    curation_status: null,
    edition: null,
    destination: null,
    project: null,
    curation_status: null,
    flight_ticket_status: null,
  });

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [destinationsOptions, setDestinationsOptions] = useState([]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    getEditions();
    getStatus();
    getProjectOptions();
    getDestinationsOptions();
  }, []);

  useEffect(() => {
    // setLoading(true);
    getData();
  }, [limit, activePage, debouncedSearchTerm, filterData]);

  const getProjectOptions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${100}&offset=${offset}`;

    fetchData(`api/project/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setProjectOptions(data);
      } else {
        setProjectOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getDestinationsOptions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${100}&offset=${offset}`;

    fetchData(`api/destination/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setDestinationsOptions(data);
      } else {
        setDestinationsOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getStatus = () => {
    fetchData(
      `api/accounts/user-choices/?type=user_status_choices`,
      "get"
    ).then((res) => {
      if (res.success) {
        setStatusList(res["user_status_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);

    fetchData(
      `customer/api/customer/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }&search=${encodeURIComponent(debouncedSearchTerm || "")}&status=${
        filterData?.status || ""
      }&score=${filterData?.score || ""}&edition=${
        filterData?.edition || ""
      }&all_users=${filterData?.all_users}&has_booking=${
        filterData?.has_booking
      }&project=${filterData?.project || ""}&destinations=${
        filterData?.destination || ""
      }&curation_status=${
        filterData?.curation_status || ""
      }&flight_ticket_status=${filterData?.flight_ticket_status || ""}`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
  };

  const scoreOptions = [
    // { value: 0, name: "0" },
    { value: 1, name: "1" },
    { value: 2, name: "2" },
    { value: 3, name: "3" },
    { value: 3.5, name: "3.5" },
    { value: 4, name: "4" },
    { value: 4.5, name: "4.5" },
    { value: 5, name: "5" },
  ];

  return (
    <div className="row g-5 g-xxl-10">
      <div className="col-xxl-12 ">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          {/*begin::Header*/}
          <div className="card-header">
            <div className="d-flex flex-stack flex-wrap gap-4">
              <div className="position-relative mr-4">
                <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                <input
                  type="text"
                  data-kt-table-widget-4="search"
                  className="form-control text-dark w-250px fs-7 ps-12 all-search"
                  name="all_search"
                  placeholder="Search customer here..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div className="position-relative mr-4">
                <div
                  className="btn btn-icon btn-primary btn-sm fw-bold me-2"
                  onClick={() => {
                    setOpenFilterDrawer(!openFilterDrawer);
                  }}
                >
                  <i class="ki-outline ki-setting-4 fs-2x"></i>
                </div>
              </div>
            </div>
            <div className="card-toolbar">
              {/* <div className="fv-row me-5">
                <label className="form-label mb-0 fs-6 me-2">
                  {"All Users"}
                </label>
                <Checkbox
                  checked={filterData?.all_users}
                  onChange={(e) => {
                    console.log(e, "e");
                    if (e.target.checked) {
                      setFilterDataValue("all_users", "1");
                    } else {
                      setFilterDataValue("all_users", null);
                    }
                  }}
                />
              </div>

              <div className="me-5">
                <Select
                  value={filterData?.status}
                  showSearch
                  optionFilterProp="label"
                  onChange={(value) => setFilterDataValue("status", value)}
                  placeholder="Select Status"
                  style={{ width: "200px" }}
                  options={statusList.map((h) => ({
                    label: h.display_name,
                    value: h.value,
                  }))}
                  allowClear
                  onClear={() => {
                    setFilterDataValue("status", null);
                  }}
                  size="large"
                />
                
              </div>

              <div className="fv-row me-5">
                <Select
                  value={filterData?.score}
                  showSearch
                  optionFilterProp="label"
                  onChange={(value) => setFilterDataValue("score", value)}
                  placeholder="Select Score"
                  style={{ width: "200px" }}
                  options={scoreOptions.map((h) => ({
                    label: h.name,
                    value: h.value,
                  }))}
                  allowClear
                  onClear={() => {
                    setFilterDataValue("score", null);
                  }}
                  size="large"
                />
              </div>

              <div className="fv-row me-5">
                <Select
                  value={filterData?.edition}
                  showSearch
                  optionFilterProp="label"
                  onChange={(value) => setFilterDataValue("edition", value)}
                  placeholder="Select Edition"
                  style={{ width: "200px" }}
                  options={editionsList}
                  allowClear
                  onClear={() => {
                    setFilterDataValue("edition", null);
                  }}
                  size="large"
                />
              </div> */}

              {/* <div
                data-kt-daterangepicker="true"
                data-kt-daterangepicker-opens="left"
                className="btn btn-sm btn-white border border-gray-300 me-3 d-flex align-items-center px-4"
              >
            
                <div className="text-gray-600 fw-bold">
                  Loading date range...
                </div>
                
                <i className="ki-outline ki-calendar-8 fs-1 ms-2 me-0" />
              </div> */}
              {/*end::Daterangepicker*/}
              {/* <a class="btn btn-primary fs-7 fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_edition"><i class="ki-outline ki-plus-square fs-2"></i>Add Edition</a> */}
              {/* <a href="add-edition.html" class="btn btn-primary fs-7 fw-bold"><i class="ki-outline ki-plus-square fs-2"></i> Add Edition</a> */}
              {/* <a href="#" class="btn  fs-7 btn-primary fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Add New User</a> */}
            </div>
          </div>
          {/*end::Header*/}

          <div className="card-body py-2 pb-7 main-box">
            {/*begin::Table container*/}
            {loading ? (
              <div className="table-responsive tbl-sales">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {data?.length > 0 ? (
                  <>
                    {/*begin::Table container*/}
                    <div className="table-responsive tbl-sales">
                      {/*begin::Table*/}
                      <table
                        className="table align-middle table-row-dashed  table-row-gray-300 gy-2 gs-4 border-top-d"
                        id
                      >
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-white">
                            <th className="min-w-50px">Sr.no</th>
                            <th className="min-w-150px">Users</th>
                            {/* <th className="min-w-75px">Number</th> */}
                            <th className="min-w-70px text-center">Score</th>
                            <th className="min-w-90px">Status</th>
                            <th className="min-w-80px">Edition Name</th>
                            <th className="min-w-80px">Customer Segment</th>
                            <th className="min-w-100px">City</th>
                            <th className="min-w-100px ">Resource Manager</th>
                            {/* <th className="min-w-105px">Date of Birth</th> */}
                            {/* <th className="min-w-85px">Payment Made</th>
                        <th className="min-w-100px">Top Reason</th> */}
                            <th className="min-w-100px text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody className=" fw-semibold fs-7">
                          {data?.length > 0
                            ? data?.map((item, index) => (
                                <tr
                                  key={index}
                                  className="bg-light cursor-pointer"
                                >
                                  <td className="bdr-left-user text-center">
                                    {index + 1}
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                        {item?.photo ? (
                                          <img
                                            src={item?.photo}
                                            alt=""
                                            width={60}
                                          />
                                        ) : (
                                          <div class="symbol symbol-circle symbol-50px mr-3">
                                            <span
                                              class="symbol-label fs-2 text-primary bg-light-primary"
                                              style={{
                                                border: "1px dashed #7843e6",
                                                borderRadius: "50%",
                                              }}
                                            >
                                              {item?.first_name
                                                ?.charAt(0)
                                                .toUpperCase() || "-"}
                                            </span>
                                          </div>
                                        )}
                                      </div>

                                      {/* <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                    <img src="/media/blank.png" alt="" />
                                  </div> */}
                                      <div className="d-flex flex-column">
                                        <Link
                                          to={`${routesList[
                                            "customer_details"
                                          ].path.replace(":id", item.uuid)}`}
                                          className="text-primary mb-1 fw-bolder"
                                        >
                                          {item.first_name +
                                            " " +
                                            item.last_name || "-"}
                                        </Link>

                                        {/* <Link
                                      to={routesList["customer_details"].path}
                                      className="text-primary mb-1 fw-bolder"
                                    >
                                      {item.first_name + " " + item.last_name}
                                    </Link> */}
                                        <span className="text-gray-600">
                                          {item.email || "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  {/* <td>{item.phone || "-"}</td> */}
                                  <td>
                                    {circularProgressBar(
                                      item.score || 0,
                                      35,
                                      3
                                    )}

                                    {/* <div className="progress-circle p10">
                                  <span>{item.score}</span>
                                  <div className="left-half-clipper">
                                    <div className="first50-bar" />
                                    <div className="value-bar" />
                                  </div>
                                </div> */}
                                  </td>
                                  <td>
                                    <span
                                      className={`badge badge-light-${
                                        item?.latest_edition_booking &&
                                        (item?.latest_edition_booking?.status ==
                                        1
                                          ? "warning"
                                          : item?.latest_edition_booking
                                              ?.status == 2
                                          ? "danger"
                                          : item?.latest_edition_booking
                                              ?.status == 3
                                          ? "success"
                                          : item?.latest_edition_booking
                                              ?.status == 4
                                          ? "info"
                                          : item?.latest_edition_booking
                                              ?.status == 5
                                          ? "danger"
                                          : item?.latest_edition_booking
                                              ?.status == 6
                                          ? "info"
                                          : item?.latest_edition_booking
                                              ?.status == 7
                                          ? "info"
                                          : item?.latest_edition_booking
                                              ?.status == 8
                                          ? "success"
                                          : item?.latest_edition_booking
                                              ?.status == 9
                                          ? "danger"
                                          : "dark")
                                      }`}
                                    >
                                      {(item?.latest_edition_booking &&
                                        item?.latest_edition_booking
                                          ?.status_label) ||
                                        "-"}
                                      {/* {item?.latest_edition_booking &&
                                        (item?.latest_edition_booking?.status ==
                                        1
                                          ? "Pending"
                                          : item?.latest_edition_booking
                                              ?.status == 2
                                          ? "Abandon"
                                          : item?.latest_edition_booking
                                              ?.status == 3
                                          ? "Approved"
                                          : item?.latest_edition_booking
                                              ?.status == 4
                                          ? "Attended"
                                          : item?.latest_edition_booking
                                              ?.status == 5
                                          ? "Rejected"
                                          : "-")} */}
                                    </span>
                                  </td>
                                  <td>
                                    {(item?.latest_edition_booking &&
                                      item?.latest_edition_booking
                                        ?.edition_name) ||
                                      "-"}
                                  </td>
                                  <td>{item.customer_segment || "-"}</td>
                                  <td>{item.city_name || "-"}</td>
                                  <td>{item.curator_name || "-"}</td>

                                  {/* <td>{item.date_of_birth || "-"}</td> */}
                                  {/* <td>{item.something}</td>
                              <td>{item.anotherThing}</td> */}
                                  <td>
                                    <div className="d-flex flex-shrink-0 justify-content-end">
                                      <Link
                                        to={`${routesList[
                                          "curation_central"
                                        ].path.replace(":id", item.uuid)}`}
                                        className="btn btn-primary btn-sm me-2"
                                      >
                                        Curate
                                      </Link>

                                      {/* <Link
                                    to={routesList["curation_central"].path}
                                    className="btn btn-primary btn-sm me-2"
                                  >
                                    Curate
                                  </Link> */}
                                      <Link
                                        to={`${routesList[
                                          "customer_details"
                                        ].path.replace(":id", item.uuid)}`}
                                        className="btn btn-icon bg-light btn-active-color-primary btn-sm me-2"
                                      >
                                        <i className="ki-outline ki-pencil fs-2 text-info" />
                                      </Link>

                                      {/* <button
                                    className="btn btn-icon bg-light btn-active-color-primary btn-sm me-2"
                                    // onClick={() => {
                                    //   set_user_data(item);
                                    //   setIsOpenUserModal(true);
                                    // }}
                                  >
                                    
                                  </button> */}

                                      {/* <a
                                    href="#"
                                    className="btn btn-icon bg-white btn-active-color-primary btn-sm me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_add_customer"
                                  >
                                    <i className="ki-outline ki-pencil fs-2 text-info" />
                                  </a> */}
                                      {/* <a
                                        href="#"
                                        className="btn btn-icon bg-white btn-active-color-primary btn-sm me-2"
                                      >
                                        <i className="ki-outline ki-trash fs-2 text-danger" />
                                      </a> */}
                                      <a
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_download"
                                        className="btn p-0 btn-icon btn-active-color-primary btn-sm"
                                      >
                                        <img
                                          src="/media/csv.png"
                                          className="h-25px"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            : "No Data Found"}
                        </tbody>
                      </table>
                      {/*end::Table*/}
                    </div>
                    {/*end::Table container*/}
                    {/*begin::Separator*/}
                    <div className="separator separator-dashed border-gray-200 mb-n4" />
                    {/*end::Separator*/}
                    <Pagination
                      setLimit={setLimit}
                      data={data}
                      activePage={activePage}
                      totalRecords={totalRecords}
                      onChange={(pageNumber) => {
                        setLoading(true);
                        setActivePage(pageNumber);
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*end::Pagination*/}
          </div>
        </div>
        {/*end::Tables Widget 9*/}
      </div>

      {isOpenUserModal && (
        <CustomerModal
          show={isOpenUserModal}
          onHide={() => {
            set_user_data({});
            setIsOpenUserModal(false);
            setLoading(true);
            getData();
          }}
          data={user_data}
        />
      )}

      {openFilterDrawer && (
        <FilterDrawer
          show={openFilterDrawer}
          onHide={() => {
            // set_user_data({});
            setOpenFilterDrawer(false);
            // setLoading(true);
            // getData();
          }}
          data={user_data}
          filterData={filterData}
          setFilterDataValue={setFilterDataValue}
          editionsList={editionsList}
          statusList={statusList}
          projectList={projectOptions}
          destinationList={destinationsOptions}
        />
      )}
    </div>
  );
}
