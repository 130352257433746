import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { Image as AntdImage, Select, Tooltip } from "antd";

import { LIMITS } from "../../../../config";
import { validateFields } from "../../../../components/validation";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import { type } from "jquery";

export default function Destinations({ show, item, onHide, genderOptions }) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);

  // Define initial state object
  const [formState, setFormState] = useState({
    destinationName: "",
    country: null,
    state: null,
    city: null,
    region: null,
    visaRequired: null,
    description: "",
  });

  const [image, setImage] = useState(null);
  const [imagePreviews, setImagePreviews] = useState(null);
  const [highlights, setHighlights] = useState([
    { highlights_image: "", highlights_text: "", imagePreview: null },
  ]);
  const [highlightLoading, setHighlightLoading] = useState(false);

  useEffect(() => {
    console.log(item, "item");
    if (item?.id) {
      setFormState({
        destinationName: item.name || "",
        country: item.country || "",
        state: item.state || "",
        city: item.city || "",
        region: item.region || "",
        visaRequired: item.is_visa || false,
        description: item.description || "",
      });

      setImage(item.image);
      setImagePreviews(item?.image);

      if (
        Array.isArray(item?.destination_highlights) &&
        item?.destination_highlights.length > 0
      ) {
        const getValues = item?.destination_highlights.map((item) => ({
          highlights_image: item?.highlights_image || "",
          highlights_text: item?.highlights_text || "",
          imagePreview: item?.highlights_image || "",
        }));
        setHighlights(getValues);
      }
    } else {
      setFormState({
        destinationName: "",
        country: null,
        state: null,
        city: null,
        region: null,
        visaRequired: null,
        description: "",
      });
      setImage(null);
      setImagePreviews(null);
      setHighlights([
        { highlights_image: "", highlights_text: "", imagePreview: null },
      ]);
    }
  }, [item]);

  const fetchPresignedUrls = async (filesData) => {
    const body = {
      files: filesData,
      thumbnail_files: filesData,
      type: "destination",
    };

    try {
      const response = await fetchData(
        "api/editions/pre-sign-urls/",
        "POST",
        body
      );
      if (response.success) {
        return {
          files: response.pre_signed_urls,
          thumbnails: response.pre_thumbnail_urls,
        };
      } else {
        message.error(response.message);
        return null;
      }
    } catch (error) {
      console.error("Error fetching presigned URLs:", error);
      message.error("Error fetching presigned URLs");
      return null;
    }
  };

  const uploadAndCompressImage = async (file, index) => {
    message.loading("Uploading image...", 0);
    setHighlightLoading(true);

    const filesData = [
      {
        file_name: `${uuidv4()}.${file.name.split(".")[1]}`,
        file_type: file.type,
      },
    ];

    const presignedUrls = await fetchPresignedUrls(filesData);
    if (!presignedUrls) {
      setHighlightLoading(false);
      return;
    }

    const { files, thumbnails } = presignedUrls;
    const presignedUrl = files[0]?.pre_signed_url;
    const fileUrl = files[0]?.file_url;
    const thumbUrl = thumbnails[0]?.file_url;

    if (!presignedUrl) {
      console.error(`No presigned URL available for file: ${file.name}`);
      setHighlightLoading(false);
      return;
    }

    try {
      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        headers: { "Content-Type": file.type },
        body: file,
      });

      if (uploadResponse.ok) {
        // Check if it's an image, then compress and upload thumbnail if necessary
        if (thumbUrl && file.type.startsWith("image/")) {
          const compressedImage = await compressImage(file, 200);

          const thumbResponse = await fetch(thumbUrl, {
            method: "PUT",
            headers: { "Content-Type": compressedImage.type },
            body: compressedImage,
          });

          if (thumbResponse.ok) {
            setHighlights((prevHighlights) =>
              prevHighlights.map((highlight, i) =>
                i === index
                  ? { ...highlight, highlights_image: fileUrl }
                  : highlight
              )
            );
          } else {
            console.error(`Thumbnail for ${file.name} upload failed`);
          }
        } else {
          setHighlights((prevHighlights) =>
            prevHighlights.map((highlight, i) =>
              i === index
                ? { ...highlight, highlights_image: fileUrl }
                : highlight
            )
          );
        }
      } else {
        console.error(
          `File ${file.name} upload failed: ${uploadResponse.statusText}`
        );
      }
    } catch (error) {
      console.error(
        `An error occurred while uploading file ${file.name}:`,
        error
      );
    }

    message.destroy();
    setHighlightLoading(false);
  };

  const compressImage = (file, maxSize) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          let width = img.width;
          let height = img.height;

          if (width > height && width > maxSize) {
            height = Math.floor((height * maxSize) / width);
            width = maxSize;
          } else if (height > maxSize) {
            width = Math.floor((width * maxSize) / height);
            height = maxSize;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              } else {
                reject(new Error("Compression failed"));
              }
            },
            file.type,
            0.7
          );
        };
        img.onerror = (error) => reject(error);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleAddHighlight = () => {
    setHighlights([
      ...highlights,
      { highlights_image: "", highlights_text: "", imagePreview: null },
    ]);
  };

  const handleRemoveHighlight = (index) => {
    setHighlights(highlights.filter((_, i) => i !== index));
  };

  const handleImageUpload = (index, file) => {
    const previewUrl = URL.createObjectURL(file);
    setHighlights((prevHighlights) =>
      prevHighlights.map((highlight, i) =>
        i === index
          ? { ...highlight, highlights_image: file, imagePreview: previewUrl }
          : highlight
      )
    );
    uploadAndCompressImage(file, index);
  };

  const handleRemoveImagePreview = (index) => {
    setHighlights((prevHighlights) =>
      prevHighlights.map((highlight, i) =>
        i === index
          ? { ...highlight, highlights_image: "", imagePreview: null }
          : highlight
      )
    );
  };

  const handleTextChange = (index, text) => {
    setHighlights((prevHighlights) =>
      prevHighlights.map((highlight, i) =>
        i === index ? { ...highlight, highlights_text: text } : highlight
      )
    );
  };

  // Handle changes for all input fields
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "file" ? e.target.files[0] : value,
    }));
  };

  // Handle select changes specifically
  const handleSelectChange = (name, value) => {
    setFormState((prevState) => {
      if (name === "country") {
        return {
          ...prevState,
          country: value,
          state: null,
          city: null,
        };
      } else if (name === "state") {
        return {
          ...prevState,
          state: value,
          city: null,
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  // Handle image preview and removal
  const handleImagesChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImage(file);
        setImagePreviews(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImages = () => {
    setImage(null);
    setImagePreviews(null);
  };

  useEffect(() => {
    if (show) {
      getCountries();
      getRegions();
    }
  }, [show]);

  useEffect(() => {
    if (formState?.country) {
      getStates();
    }
  }, [formState?.country]);

  useEffect(() => {
    if (formState?.state) {
      getCities();
    }
  }, [formState?.state]);

  const getCountries = () => {
    fetchData(`api/country/?is_active=1&limit=${300}&offset=${0}`).then(
      (res) => {
        if (res.success) {
          setCountries(res.data);
        } else {
          setCountries([]);
          toast.error(res.message);
        }
      }
    );
  };

  const getStates = () => {
    fetchData(
      `api/country/state/?is_active=1&limit=${300}&offset=${0}&country_id=${
        formState?.country
      }`
    ).then((res) => {
      if (res.success) {
        setStates(res.data);
      } else {
        setStates([]);
        toast.error(res.message);
      }
    });
  };

  const getCities = () => {
    fetchData(
      `api/country/state/city/?is_active=1&limit=${300}&offset=${0}&state_id=${
        formState.state
      }`
    ).then((res) => {
      if (res.success) {
        setCities(res.data);
      } else {
        setCities([]);
        toast.error(res.message);
      }
    });
  };

  const getRegions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    setLoading(true);

    fetchData(`api/region/${param}`).then((res) => {
      setLoading(false);
      if (res.success) {
        const newData = res.data?.map((r) => {
          return {
            label: r?.name,
            value: r?.id,
          };
        });

        setRegions(newData);
      } else {
        setRegions([]);
        toast.error(res.message);
      }
    });
  };

  const validationRules = {
    destinationName: {
      required: true,
    },
    region: {
      required: true,
    },
    visaRequired: {
      required: false,
    },
    country: {
      required: true,
    },
    state: {
      required: false,
    },
    city: {
      required: false,
    },
    description: {
      required: false,
    },
  };

  const onSubmit = () => {
    const { isValid } = validateFields(formState, validationRules);

    if (!isValid) {
      return;
    } else {
      if (!image) {
        toast.error("Please select an image");
        return;
      } else {
        if (item?.id) {
          toast.loading("Updating...");
        } else {
          toast.loading("Adding...");
        }
      }
    }

    setSending(true);

    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const hightlightValues =
      highlights &&
      highlights.map((h) => {
        return {
          highlights_image: h.highlights_image,
          highlights_text: h.highlights_text,
        };
      });

    const body = {
      ...(formState.destinationName && { name: formState.destinationName }),
      ...(formState.visaRequired !== undefined && {
        is_visa: formState.visaRequired,
      }),
      ...(formState.region && { region: formState.region }),
      ...(formState.country && { country: formState.country }),
      ...(formState.state && { state: formState.state }),
      ...(formState.city && { city: formState.city }),
      ...(formState.description && { description: formState.description }),
      ...(hightlightValues &&
        hightlightValues.length > 0 && {
          destination_highlights: hightlightValues,
        }),
      ...(image && !isURL(image) && { image: image || "" }),
    };

    // const body = {
    //   name: formState.destinationName,
    //   is_visa: formState.visaRequired,
    //   region: formState.region,
    //   country: formState.country,
    //   state: formState.state,
    //   city: formState?.city || " ",
    //   description: formState.description,
    //   destination_highlights: hightlightValues || [],
    //   ...(image && isURL(image) ? {} : { image: image || "" }),
    // };

    console.log(body, "body");

    let method = item?.id ? "put" : "post";
    let api = item?.id ? `api/destination/${item.id}/` : "api/destination/";

    fetchData(api, method, body, "", true).then((res) => {
      setSending(false);
      toast.dismiss();
      if (res.success) {
        toast.success("Success");
        onHide();
      } else {
        toast.error(res.message);
      }
    });
  };

  const [inputValue, setInputValue] = useState("");

  const handleSearch = (value) => {
    setInputValue(value);
  };

  // Generate options, including custom input if not found in `cities`
  const options = [
    ...cities.map((e) => ({ label: e.name, value: e.id })),
    ...(inputValue && !cities.some((e) => e.name === inputValue)
      ? [{ label: inputValue, value: inputValue }]
      : []),
  ];

  return (
    <Modal centered show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {item?.id ? "Edit Destination" : "Add New Destination"}
        </h3>
      </Modal.Header>
      <div className="modal-body pb-7 pt-5 px-lg-10">
        <div
          className="scroll-y pe-7 ps-3"
          id="kt_modal_add_customer_scroll"
          style={{
            height: "500px",
          }}
        >
          <div className="row g-5 mb-5 align-items-center">
            {/* Image Field */}
            <div className="col-md-6 fv-row my-5">
              <label className="fs-6 fw-bold mb-2">Image</label>
              {imagePreviews ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  <AntdImage
                    src={imagePreviews}
                    alt="Image Preview"
                    style={{
                      width: "150px",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      border: "2px solid #ccc",
                    }}
                  />
                  <i
                    title="Remove Image"
                    className="ki-outline ki-cross fs-2"
                    onClick={() => handleRemoveImages()}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      cursor: "pointer",
                      zIndex: 2,
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ) : (
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  name="image"
                  className="form-control fs-7"
                  onChange={(e) => handleImagesChange(e)}
                />
              )}
            </div>

            {/* Destination Name Field */}
            <div className="col-md-6 fv-row my-5">
              <label className="fs-6 fw-bold mb-2">Destination Name</label>
              <input
                type="text"
                name="destinationName"
                placeholder="Destination Name"
                className="form-control"
                value={formState.destinationName}
                onChange={handleChange}
              />
            </div>

            {/* Region Select */}
            <div className="col-md-6 fv-row mb-4">
              <label className="fs-6 fw-bold mb-2">Region</label>
              <Select
                showSearch
                name="region"
                value={formState.region}
                onChange={(value) => handleSelectChange("region", value)}
                placeholder="Select Region"
                className="fs-7 w-100"
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={regions}
              />
            </div>

            {/* Visa Required Field */}
            <div className="col-md-6 fv-row mb-4">
              <label className="fs-6 fw-bold mb-2">Is visa required?</label>
              <Select
                showSearch
                name="visaRequired"
                value={formState.visaRequired}
                onChange={(value) => handleSelectChange("visaRequired", value)}
                placeholder="Select Visa"
                className="fs-7 w-100"
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            </div>

            {/* Country Select */}
            <div className="col-md-4 fv-row ">
              <label className="fs-6 fw-bold mb-2">Country</label>
              <Select
                showSearch
                optionFilterProp="label"
                name="country"
                value={formState?.country}
                onChange={(value) => handleSelectChange("country", value)}
                placeholder="Select Country"
                className="fs-7 w-100"
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={countries?.map((e) => {
                  return { label: e.name, value: e.id };
                })}
              />
            </div>

            {/* State Select */}
            <div className="col-md-4 fv-row">
              <label className="fs-6 fw-bold mb-2">State</label>
              <Select
                showSearch
                optionFilterProp="label"
                name="state"
                value={formState.state}
                onChange={(value) => handleSelectChange("state", value)}
                placeholder="Select State"
                className="fs-7 w-100"
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={states?.map((e) => {
                  return { label: e.name, value: e.id };
                })}
              />
            </div>

            {/* City Select */}
            <div className="col-md-4 fv-row">
              <label className="fs-6 fw-bold mb-2">City</label>
              <Select
                showSearch
                optionFilterProp="label"
                name="city"
                value={formState.city}
                onChange={(value) => handleSelectChange("city", value)}
                placeholder="Select City"
                className="fs-7 w-100"
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={options}
                onSearch={handleSearch} // Capture typed input
                // options={cities?.map((e) => {
                //   return { label: e.name, value: e.id };
                // })}
              />
            </div>

            <div className="col-md-12 fv-row mt-7">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Description
              </label>
              <textarea
                name="description"
                className="form-control fs-7"
                id
                cols={30}
                rows={3}
                value={formState?.description || ""}
                onChange={handleChange}
                placeholder="Enter Description"
              />
            </div>

            <div className="separator separator-dashed mt-5"></div>

            <label className="fs-2 fw-bold mb-2 me-2">Highlights</label>

            <div className="row mt-7">
              {highlights.map((highlight, index) => (
                <div key={index} className="row align-items-center  mb-3">
                  {/* Image Section */}
                  <div className="col-md-3">
                    <label className="fs-6 fw-bold mb-2 me-2">Image</label>
                    {highlight.imagePreview ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginBottom: "10px",
                        }}
                      >
                        <AntdImage
                          src={highlight.imagePreview}
                          alt="Image Preview"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            border: "2px solid #ccc",
                          }}
                        />
                        <i
                          title="Remove Image"
                          className="ki-outline ki-cross fs-2"
                          onClick={() => handleRemoveImagePreview(index)}
                          style={{
                            position: "absolute",
                            top: "-8px",
                            right: "-8px",
                            cursor: "pointer",
                            zIndex: 2,
                            backgroundColor: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className="form-control fs-7 mb-3"
                        onChange={(e) =>
                          handleImageUpload(index, e.target.files[0])
                        }
                      />
                    )}
                  </div>

                  {/* Text Area for Highlight Text */}
                  <div className="col-md-8">
                    <label className="fs-6 fw-bold mb-2">Text</label>
                    <textarea
                      className="form-control mb-3"
                      placeholder="Enter highlight text"
                      value={highlight.highlights_text}
                      onChange={(e) => handleTextChange(index, e.target.value)}
                      cols={30}
                      rows={highlight.imagePreview ? 3 : 1}
                    />
                  </div>

                  {/* Add and Remove Buttons */}
                  <div className="col-md-1">
                    <div className="d-flex flex-column align-items-center mt-4">
                      <Tooltip title="Add Highlight">
                        {/* Show plus button only on the first index */}
                        {index === 0 && (
                          <i
                            className="ki-outline fs-1 ki-plus-square text-success me-2"
                            onClick={handleAddHighlight}
                            style={{ cursor: "pointer" }}
                          ></i>
                        )}
                      </Tooltip>

                      <Tooltip title="Remove Highlight">
                        {/* Show minus button for all indexes if there is more than one highlight */}
                        {index >= 1 && (
                          <i
                            className="ki-outline fs-1 ki-minus-square text-danger"
                            onClick={() => handleRemoveHighlight(index)}
                            style={{ cursor: "pointer" }}
                          ></i>
                        )}
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}

              {/* Display Array of Highlights in JSON Format */}
              {/* <pre>{JSON.stringify(highlights, null, 2)}</pre> */}
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={onSubmit}
          disabled={sending || highlightLoading}
        >
          {sending ? (
            <span>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </Modal>
  );
}
