import { routesList } from "./config";
import {
  AdMetrics,
  BusinessMetrics,
  CallRequests,
  CancellationPolicy,
  ChangeEdition,
  CurationCentral,
  CustomerDetails,
  Customers,
  Dashboard,
  FAQs,
  ForgotPassword,
  Login,
  ManageEditions,
  OnboardingCalls,
  Payments,
  Profile,
  RefundPolicy,
  Register,
  RoomManager,
  Settings,
  VisaCenter,
} from "./pages";

import {
  HandleEditions,
  ManageAddOns,
  ManageDestinations,
  ManageExperiences,
  ManageHotels,
  Projects,
} from "./pages/manage";
import { ChangePassword, Feedback, ManageEmployee } from "./pages/settings";
import { BookingsPage } from "./pages/bookings";
import BookingDetail from "./pages/bookings/bookings-page/details";
import ProjectSettings from "./pages/settings/project-settings";
import VisaGuidelines from "./pages/settings/visa-guidelines";
import Tribe from "./pages/Tribe";

export const routes = [
  {
    path: routesList["login"].path,
    element: <Login />,
    layout: false,
  },
  {
    path: routesList["register"].path,
    element: <Register />,
    layout: false,
  },
  {
    path: routesList["forgot_password"].path,
    element: <ForgotPassword />,
    layout: false,
  },
  {
    path: routesList["/"].path,
    element: <Dashboard />,
    layout: true,
  },
  {
    path: routesList["projects"].path,
    element: <Projects />,
    layout: true,
  },
  {
    path: routesList["manage_editions"].path,
    element: <ManageEditions />,
    layout: true,
  },
  {
    path: routesList["handle_editions"].path,
    element: <HandleEditions />,
    layout: true,
  },
  {
    path: routesList["handle_editions_detail"].path,
    element: <HandleEditions />,
    layout: true,
  },

  {
    path: routesList["manage_addons"].path,
    element: <ManageAddOns />,
    layout: true,
  },
  {
    path: routesList["manage_hotels"].path,
    element: <ManageHotels />,
    layout: true,
  },
  {
    path: routesList["manage_destinations"].path,
    element: <ManageDestinations />,
    layout: true,
  },
  {
    path: routesList["manage_experiences"].path,
    element: <ManageExperiences />,
    layout: true,
  },
  {
    path: routesList["customers"].path,
    element: <Customers />,
    layout: true,
  },
  {
    path: routesList["customer_details"].path,
    element: <CustomerDetails />,
    layout: true,
  },
  {
    path: routesList["curation_central"].path,
    element: <CurationCentral />,
    layout: true,
  },
  {
    path: routesList["payments"].path,
    element: <Payments />,
    layout: true,
  },
  {
    path: routesList["bookings"].path,
    element: <BookingsPage />,
    layout: true,
  },
  {
    path: routesList["bookings_detail"].path,
    element: <BookingDetail />,
    layout: true,
  },
  {
    path: routesList["call_requests"].path,
    element: <CallRequests />,
    layout: true,
  },
  {
    path: routesList["onboarding_calls"].path,
    element: <OnboardingCalls />,
    layout: true,
  },
  {
    path: routesList["change_edition"].path,
    element: <ChangeEdition />,
    layout: true,
  },
  {
    path: routesList["visa_center"].path,
    element: <VisaCenter />,
    layout: true,
  },
  {
    path: routesList["room_manager"].path,
    element: <RoomManager />,
    layout: true,
  },
  {
    path: routesList["faqs"].path,
    element: <FAQs />,
    layout: true,
  },
  {
    path: routesList["change_password"].path,
    element: <ChangePassword />,
    layout: true,
  },
  {
    path: routesList["ad_metrics"].path,
    element: <AdMetrics />,
    layout: true,
  },
  {
    path: routesList["business_metrics"].path,
    element: <BusinessMetrics />,
    layout: true,
  },
  {
    path: routesList["cancellation_policy"].path,
    element: <CancellationPolicy />,
    layout: true,
  },
  {
    path: routesList["refund_policy"].path,
    element: <RefundPolicy />,
    layout: true,
  },
  // {
  //   path: routesList["settings"].path,
  //   element: <Settings />,
  //   layout: true,
  // },
  {
    path: routesList["manage_employee"].path,
    element: <ManageEmployee />,
    layout: true,
  },
  {
    path: routesList["project_settings"].path,
    element: <ProjectSettings />,
    layout: true,
  },
  {
    path: routesList["feedback"].path,
    element: <Feedback />,
    layout: true,
  },
  {
    path: routesList["visa_guideline"].path,
    element: <VisaGuidelines />,
    layout: true,
  },
  {
    path: routesList["tribe"].path,
    element: <Tribe />,
    layout: true,
  },
  {
    path: routesList["my_profile"].path,
    element: <Profile />,
    layout: true,
  },
  {
    path: "*",
    element: <h1>Not found</h1>,
    layout: true,
  },
];
